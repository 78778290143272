const generateRandomString = (length = 10) =>
  Math.random()
    .toString(20)
    .substr(2, length);

let allMaster = [
  "SCN_BRANCH",
  "SCN_DEPARTMENT",
  "SCN_DESIGNATION",
  "SCN_SUB_DEPARTMENT",
  "SCN_REGION",
  "SCN_PROCESS",
  "SCN_SERVICE_CATEGORY",
  "SCN_SERVICE",
  "SCN_UOM",
  "SCN_KPI_TYPE",
  "SCN_CHANNEL",
  "SCN_SUB_CHANNEL",
  "SCN_DIVISION",
  "SCN_SUB_DIVISION",
  "SCN_CATEGORY",
  "SCN_PERFORMANCE_RATING",
  "SCN_PAYROLL",
  "SCN_EMPLOYEE",
  "SCN_TARGET_TYPE",
  "SCN_STORE",
];
let CommoNMaster = [
  "SCN_BRANCH",
  "SCN_DEPARTMENT",
  "SCN_DESIGNATION",
  "SCN_SUB_DEPARTMENT",
  "SCN_REGION",
  "SCN_PROCESS",
  "SCN_EMPLOYEE",
];
let CHCKMaster = [];
let TKTMaster = ["SCN_SERVICE_CATEGORY", "SCN_SERVICE"];
let WLKMaster = ["SCN_WALKIN_OCCASION", "SCN_WALKIN_CAMPAIGN"];
let VSTMaster = ["SCN_VISIT_CUSTOMER", "SCN_VISIT_POM", "SCN_VISIT_TYPE"];
let PMSMaster = [
  "SCN_UOM",
  "SCN_KPI_TYPE",
  "SCN_CHANNEL",
  "SCN_SUB_CHANNEL",
  "SCN_DIVISION",
  "SCN_SUB_DIVISION",
  "SCN_CATEGORY",
  "SCN_PERFORMANCE_RATING",
  "SCN_TARGET_TYPE",
  "SCN_STORE",
  "SCN_PAYROLL",
];
let BPMaster = ["SCN_OBJECTIVE", "SCN_STRATEGY_GROUP"];
let FMSMaster = [
  "SCN_FMS_AMC_CANCEL_CAT",
  "SCN_FMS_AMC_TYPE",
  "SCN_FMS_AREA",
  "SCN_FMS_BUILDING_TYPE",
  "SCN_FMS_CAR_OPERATOR_MAKE",
  "SCN_FMS_CITY",
  "SCN_FMS_CLIENT_TYPE",
  "SCN_FMS_CLIENT_SUBTYPE",
  "SCN_FMS_CONTROLLER",
  "SCN_FMS_CUSTOMER_GROUP",
  "SCN_FMS_CALL_FUNCTION_TYPE",
  "SCN_FMS_DOORDRIVE_TYPE",
  "SCN_FMS_DRIVE_TYPE",
  "SCN_FMS_ESCLATION_TIME",
  "SCN_FMS_ESCLATION_PER",
  "SCN_FMS_ENCODER_MODEL",
  "SCN_FMS_ENCODER_MAKE",
  "SCN_FMS_EXPERTISE_LEVEL",
  "SCN_FMS_INVERTER_MAKE",
  "SCN_FMS_INSTRUMENT_TYPE",
  "SCN_FMS_LANDING_DISPLAY",
  "SCN_FMS_LIFT_CATEGORY",
  "SCN_FMS_LIFT_TYPE",
  "SCN_FMS_LIFT_MAKE",
  "SCN_FMS_LOCATION",
  "SCN_FMS_MACHINE_ROOM",
  "SCN_FMS_MENU_ACCESS",
  "SCN_FMS_MATERIAL",
  "SCN_FMS_MAINDRIVE_MAKE",
  "SCN_FMS_MACHGEAR_MAKE",
  "SCN_FMS_MOTOR_MAKE",
  "SCN_FMS_PAYMENT_TYPE",
  "SCN_FMS_PAYMENT_TERM",
  "SCN_FMS_RECEIPT_MODE",
  "SCN_FMS_REFNO_TYPE",
  "SCN_FMS_RENEWAL_TYPE",
  "SCN_FMS_ROUTE",
  "SCN_FMS_SCH_VISIT",
  "SCN_FMS_SERVICE",
  "SCN_FMS_SPARE_PARTS",
  "SCN_FMS_SPARE_GROUP",
  "SCN_FMS_STATE",
  "SCN_FMS_TAX",
  "SCN_FMS_TAX_CAT",
  "SCN_FMS_TEAM",
  "SCN_FMS_TKT_CAT",
  "SCN_FMS_TKT_SUBCAT",
  "SCN_FMS_PAUSE_CAT",
  "SCN_FMS_VISTI_SCHEDULE",
  "SCN_FMS_CUSTOMER_DESIGNATION",
  "SCN_FMS_CANCELLATION_REASON",
  "SCN_FMS_RECEIPT_NARRATIONS",
  "SCN_FMS_CUSTOMER_GRADE",
  "SCN_FMS_HIGHWAY_LANDING_TYPE",
  "SCN_FMS_DEDUCTION",
  "SCN_FMS_REASON_FOR_REQUIREMENT",
  "SCN_FMS_PRICE_MASTER",
  "SCN_FMS_MR_LANDING",
  "SCN_FMS_MR_QUOTATION",
  "SCN_FMS_MR_CONTRACT"
];
let All_Strategy = [
  "SCN_PLANNING",
  "SCN_STRATEGY",
  "SCN_BUSINESS_REPORT",
  "SCN_BUDGET",
  "SCN_ACTUAL",
];
let All_Team = [
  "SCN_ORG_CHART",
  "SCN_PMS_KPI_ADDITION",
  "SCN_PMS_SET_TARGET",
  "SCN_PMS_EMPLOYEE_KPI",
  "SCN_PMS_SET_TARGET",
  "SCN_PMS_SET_WEIGHTAGE",
  "SCN_CUSTOMER_TARGET",
  "SCN_CUSTOMER_TARGET_REPORT",
];
let KpiSetup = [
  "SCN_PMS_EMPLOYEE_KPI",
  "SCN_PMS_SET_TARGET",
  "SCN_PMS_SET_WEIGHTAGE",
  "SCN_CUSTOMER_TARGET",
  "SCN_CUSTOMER_TARGET_REPORT",
];

let All_Act = [
  "SCN_CHCK_MY_CHECKLIST",
  "SCN_CHCK_CASE_BASED",
  "SCN_TKT_TICKETS",
  "SCN_TKT_REPORTS",
  "SCN_CHCK_REPORTS",
  "SCN_WALKINS",
  "SCN_WLK_RPRT",
  "SCN_WLKN_FEEDBACK_RPRT",
  "SCN_VISITS",
  "SCN_VISIT_REPORTS",
  "SCN_SURVEY",
  "SCN_SURVEY_REPORTS",
];
let All_Review = [
  "SCN_ANALYTICS",
  "SCN_JEWELLERY_DASHBOARD",
  "SCN_SALES_DASHBOARD",
];
let All_Reward = [
  "SCN_APPRAISAL_SET_APPRAISAL_CYCLE",
  "SCN_APPRAISAL_VIEW_APPRAISAL",
  "SCN_APPRISALTRENDREPORT",
  "SCN_INCENTIVE_POLICY",
  "SCN_INCENTIVE_SET_FREQUENCY",
];
let menu_list = [
  {
    id: generateRandomString(),
    has_children: false,
    onlytextviews: false,
    icon: "mdi-home",
    name: "Home",
    to: "/show_list",
    product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
    underline: false,
    isnonadmintoshow: true,
    screen_codeflag: ["SCN_HOME"],
    module_codeflag: ["COMMON"],
  },
  {
    id: generateRandomString(),
    name: "Strategy",
    child: true,
    has_children: true,
    screen_codeflag: All_Strategy,
    product_code: ["TECTALIK_PLANNING"],
    module_codeflag: ["PLANNING"],
    icon: "mdi-alpha-s-circle",
    children: [
      {
        id: generateRandomString(),
        name: "Business Plan",
        child: true,
        sub_children: true,
        icon: "mdi-alpha-b-circle",
        screen_codeflag: [
          "SCN_PLANNING",
          "SCN_STRATEGY",
          "SCN_BUSINESS_REPORT",
        ],
        product_code: ["TECTALIK_PLANNING"],
        module_codeflag: ["PLANNING"],
        children: [
          {
            id: generateRandomString(),
            name: "Goals",
            child: true,
            icon: "mdi-alpha-p-circle",
            to: "/business_plan",
            // screen_code: ["CREATE_CHK"],
            product_code: ["TECTALIK_PLANNING"],
            screen_codeflag: ["SCN_PLANNING"],
            module_codeflag: ["PLANNING"],
          },
          {
            id: generateRandomString(),
            name: "Strategy",
            child: true,
            icon: "mdi-alpha-s-circle",
            to: "/set_strategy",
            // screen_code: ["CREATE_CHK", "SCN_BUDGET", "SCN_ACTUAL"],
            product_code: ["TECTALIK_PLANNING"],
            screen_codeflag: ["SCN_STRATEGY"],
            module_codeflag: ["PLANNING", "FINANCIAL", "FINANCIAL"],
          },
          // {
          //     id: generateRandomString(),
          //     name: "Business Report",
          //     child: true,
          //     icon: "mdi-alpha-b-circle",
          //     to: "/business_report",
          //     screen_code: ["CREATE_CHK"],
          //     product_code: ["TECTALIK_PLANNING"],
          //     screen_codeflag: ["SCN_BUSINESS_REPORT"],
          //     module_codeflag: ["PLANNING"],
          // },
        ],
      },
      {
        id: generateRandomString(),
        name: "Financial Plan",
        child: true,
        // screen_code: ["CREATE_MASTERS"],
        product_code: ["TECTALIK_PMS"],
        sub_children: true,
        screen_codeflag: ["SCN_BUDGET", "SCN_ACTUAL"],
        module_codeflag: ["FINANCIAL", "FINANCIAL"],
        icon: "mdi-alpha-f-circle",
        children: [
          {
            id: generateRandomString(),
            name: "Budget",
            child: true,
            icon: "mdi-alpha-b-circle",
            to: "/budget",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_BUDGET"],
            module_codeflag: ["FINANCIAL"],
          },
          {
            id: generateRandomString(),
            name: "Actual",
            child: true,
            icon: "mdi-alpha-a-circle",
            to: "/actual",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_ACTUAL"],
            module_codeflag: ["FINANCIAL"],
          },
          {
            id: generateRandomString(),
            name: "Budget Reports",
            child: true,
            icon: "mdi-alpha-a-circle",
            to: "/budget_reports",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_BUDGET"],
            module_codeflag: ["FINANCIAL"],
          },
          // actual report
          {
            id: generateRandomString(),
            name: "Actual Reports",
            child: true,
            icon: "mdi-alpha-a-circle",
            to: "/actual_reports",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_ACTUAL"],
            module_codeflag: ["FINANCIAL"],
          },
        ],
      },
      {
        id: generateRandomString(),
        name: "Business Health",
        child: true,
        // screen_code: ["CREATE_MASTERS"],
        product_code: ["TECTALIK_PMS"],
        sub_children: true,
        screen_codeflag: ["SCN_BUDGET", "SCN_ACTUAL"],
        module_codeflag: ["FINANCIAL", "FINANCIAL"],
        icon: "mdi-alpha-f-circle",
        children: [
          {
            id: generateRandomString(),
            icon: "mdi-file-tree-outline",
            name: "KPI Impact Setup",
            to: "/kpi_impact_landing",
            // screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_KPI_IMPACT_SETUP"],
            module_codeflag: ["PERFORMANCE"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-sitemap",
            name: "KPI Impact Chart",
            to: "/kpi_impact_tree",
            // screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_KPI_IMPACT_TREE"],
            module_codeflag: ["PERFORMANCE"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-sitemap",
            name: "KPI Bullseye",
            to: "/kpi_impact_tree_new",
            // screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_KPI_IMPACT_TREE_NEW"],
            module_codeflag: ["PERFORMANCE"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-sitemap",
            name: "KPI Bullseye New",
            to: "/kpi_bullseye_new",
            has_children: false,
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_KPI_BULLSEYE_NEW"],
            module_codeflag: ["PERFORMANCE"],
          },
        ]
      },
      {
        id: generateRandomString(),
        name: "Business Health Check",
        child: true,
        // screen_code: ["CREATE_MASTERS"],
        product_code: ["TECTALIK_PMS"],
        sub_children: true,
        screen_codeflag: ["SCN_BUSINESS_HEALTH_CHECK"],
        module_codeflag: ["FINANCIAL"],
        icon: "mdi-alpha-b-circle",
        children: [
          {
            id: generateRandomString(),
            icon: "mdi-heart-pulse",
            name: "Health Check",
            to: "/health_check_invite_details",
            has_children: false,
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_BUSINESS_HEALTH_CHECK"],
            module_codeflag: ["FINANCIAL"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-clipboard-pulse-outline",
            name: "Health Check Report",
            to: "/health_check_report",
            has_children: false,
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_BUSINESS_HEALTH_CHECK"],
            module_codeflag: ["FINANCIAL"],
          }
        ]
      }
    ],
  },
  {
    id: generateRandomString(),
    icon: "mdi-alpha-t-circle",
    name: "Team",
    // teamdrop:"yes",
    // to: "/team_list",
    has_children: true,
    screen_codeflag: All_Team,
    product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
    module_codeflag: ["COMMON"],
    children: [
      // {
      //   id: generateRandomString(),
      //   icon: "mdi-family-tree",
      //   name: "Organization Chart",
      //   to: "/org_chart",
      //   // screen_code: ["CREATE_MASTERS"],
      //   has_children: false,
      //   product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
      //   // isnonadmintoshow: true,
      //   screen_codeflag: ["SCN_ORG_CHART"],
      //   module_codeflag: ["COMMON"],
      // },
      // {
      //   id: generateRandomString(),
      //   icon: "mdi-family-tree",
      //   // name: "Organization Chart New",
      //   name: "Organization Chart",
      //   to: "/org_chart_new",
      //   // screen_code: ["CREATE_MASTERS"],
      //   has_children: false,
      //   product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
      //   // isnonadmintoshow: true,
      //   screen_codeflag: ["SCN_ORG_CHART"],
      //   module_codeflag: ["COMMON"],
      // },
      // {
      //   id: generateRandomString(),
      //   icon: "mdi-family-tree",
      //   name: "Organization Chart Second",
      //   to: "/organization_chart",
      //   has_children: false,
      //   product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
      //   screen_codeflag: ["SCN_ORG_CHART"],
      //   module_codeflag: ["COMMON"],
      // },
      {
        id: generateRandomString(),
        icon: "mdi-family-tree",
        name: "Organization Chart",
        to: "/orgchart_sample",
        has_children: false,
        product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
        screen_codeflag: ["SCN_ORG_CHART"],
        module_codeflag: ["COMMON"],
      },
      {
        id: generateRandomString(),
        icon: "mdi-alpha-k-box",
        to: "/kpi_additions",
        name: "Kpi Addition",
        // screen_code: ["CREATE_MASTERS"],
        has_children: false,
        product_code: ["TECTALIK_PMS"],
        screen_codeflag: ["SCN_PMS_KPI_ADDITION"],
        module_codeflag: ["PERFORMANCE"],
      },
      {
        id: generateRandomString(),
        icon: "mdi-trophy-award",
        name: "KPI Updation",
        to: "/actual_ViewLanding",
        // screen_code: ["CREATE_MASTERS"],
        has_children: false,
        product_code: ["TECTALIK_PMS"],
        screen_codeflag: ["SCN_PMS_SET_TARGET"],
        module_codeflag: ["PERFORMANCE"],
      },
      {
        id: generateRandomString(),
        icon: "mdi-file-tree-outline",
        name: "Chart of Accounts",
        to: "/chart_of_accounts",
        // screen_code: ["CREATE_MASTERS"],
        has_children: false,
        product_code: ["TECTALIK_PMS"],
        screen_codeflag: ["SCN_CHART_OF_ACCOUNTS"],
        module_codeflag: ["PERFORMANCE"],
      },
      // {
      //   id: generateRandomString(),
      //   icon: "mdi-cog",
      //   name: "KPI settings",
      //   to: "/kpi_settings_landing",
      //   // screen_code: ["CREATE_MASTERS"],
      //   has_children: false,
      //   product_code: ["TECTALIK_PMS"],
      //   screen_codeflag: ["SCN_KPI_SETTING"],
      //   module_codeflag: ["PERFORMANCE"],
      // },
      {
        id: generateRandomString(),
        icon: "mdi-sitemap",
        name: "KPI Impact Chart",
        to: "/kpi_impact_tree_new",
        // screen_code: ["CREATE_MASTERS"],
        has_children: false,
        product_code: ["TECTALIK_PMS"],
        screen_codeflag: ["SCN_KPI_IMPACT_TREE_NEW"],
        module_codeflag: ["PERFORMANCE"],
      },
      {
        id: generateRandomString(),
        icon: "mdi-file-document",
        name: "Acknowledgement Report",
        to: "/kpi_role_ack_report",
        // screen_code: ["CREATE_MASTERS"],
        has_children: false,
        product_code: ["TECTALIK_PMS"],
        screen_codeflag: ["SCN_PMS_KPI_ROLES_REPORT"],
        module_codeflag: ["PERFORMANCE"],
      },
      {
        id: generateRandomString(),
        icon: "mdi-file-document",
        name: "KPI Status Report",
        to: "/kpi_overall_ack_report",
        // screen_code: ["CREATE_MASTERS"],
        has_children: false,
        product_code: ["TECTALIK_PMS"],
        screen_codeflag: ["SCN_OVERALL_ACK_REPORT"],
        module_codeflag: ["PERFORMANCE"],
      },
      {
        id: generateRandomString(),
        icon: "mdi-alpha-k-box",
        name: "Kpi Set Up",
        // screen_code: ["CREATE_MASTERS"],
        sub_children: true,
        product_code: ["TECTALIK_PMS"],
        screen_codeflag: KpiSetup,
        module_codeflag: ["PERFORMANCE"],
        children: [
          {
            id: generateRandomString(),
            icon: "mdi-account-star",
            name: " Assign KPI",
            to: "/employee_kpi",
            // screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_PMS_EMPLOYEE_KPI"],
            module_codeflag: ["PERFORMANCE"],
          },
          // {
          //     id: generateRandomString(),
          //     icon: "mdi-google-circles-group",
          //     name: " KPI Mapping",
          //     to: "/kpi/mapping",
          //     screen_code: ["CREATE_MASTERS"],
          //     has_children: false,
          //     product_code: ["TECTALIK_PMS"],
          //     screen_codeflag: ["SCN_PMS_EMPLOYEE_KPI"],
          //     module_codeflag: ["PERFORMANCE"],
          // },
          {
            id: generateRandomString(),
            icon: "mdi-bullseye-arrow",
            name: "Set Target",
            to: "/set_target_ViewLanding",
            // screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_PMS_SET_TARGET"],
            module_codeflag: ["PERFORMANCE"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-scale-balance",
            name: "Set Weightage",
            to: "/set_weightage",
            // screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_PMS_SET_WEIGHTAGE"],
            module_codeflag: ["PERFORMANCE"],
          },

        ],
      },
    ],
  },
  {
    id: generateRandomString(),
    name: "Act",
    icon: "mdi-alpha-a-circle",
    //    to: "/quick_links",
    // screen_code: ["CREATE_CHK", "RUN_REPORTS"],
    product_code: [
      "TECTALIK_CHCK",
      "TECTALIK_TKT",
      "TECTALIK_SURVEY",
      "TECTALIK_VISITS",
      "TECTALIK_WLK",
    ],
    has_children: true,
    screen_codeflag: All_Act,
    module_codeflag: ["COMPLIANCE", "WALKINS", "VISITS", "SURVEYS", "TICKETS"],
    children: [
      {
        id: generateRandomString(),
        name: "Checklist",
        icon: "mdi-alpha-c-circle",
        child: true,
        sub_children: true,
        // screen_code: ["CREATE_CHK", "RUN_REPORTS"],
        product_code: ["TECTALIK_CHCK"],
        screen_codeflag: [
          "SCN_CHCK_MY_CHECKLIST",
          "SCN_CHCK_CASE_BASED",
          "SCN_CHCK_REPORTS",
        ],
        module_codeflag: ["COMPLIANCE"],
        children: [
          {
            id: generateRandomString(),
            icon: "mdi-clipboard-edit",
            name: "My Checklist",
            to: "/MainChecklist",
            has_children: false,
            // screen_code: ["CREATE_CHK"],
            product_code: ["TECTALIK_CHCK"],
            screen_codeflag: ["SCN_CHCK_MY_CHECKLIST", "SCN_CHCK_CASE_BASED"],
            module_codeflag: ["COMPLIANCE"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-alpha-t-box",
            name: "My Tasks",
            to: "/myTasks",
            // screen_code: ["CREATE_CHK"],
            product_code: ["TECTALIK_CHCK"],
            screen_codeflag: ["SCN_CHCK_MY_CHECKLIST", "SCN_CHCK_CASE_BASED"],
            module_codeflag: ["COMPLIANCE"],
          },
          // {
          //     id: generateRandomString(),
          //     isproductiontohide: true,
          //     icon: "mdi-clipboard-edit",
          //     name: "My Checklist - New UI",
          //     to: "/uichanges",
          //     screen_code: ["CREATE_CHK"],
          //     product_code: ["TECTALIK_CHCK"],
          //     screen_codeflag: ["SCN_CHCK_MY_CHECKLIST", "SCN_CHCK_CASE_BASED"],
          //     module_codeflag: ["COMPLIANCE"],
          // },
          // {
          //   icon: "mdi-table-check",
          //   name: "Checklist Library",
          //   to: "/checklist_Template_page",
          //   screen_code: "ADOPT_CHK",
          //   product_code: ["TECTALIK_CHCK"],
          // },
          // {
          //   id: generateRandomString(),
          //   icon: "mdi-book-open-outline",
          //   name: "Task List",
          //   to: "/show_list",
          //   product_code: ["TECTALIK_CHCK"],
          //   isnonadmintoshow: true,
          // },
          // {
          //   id: generateRandomString(),
          //   icon: "mdi-book-account",
          //   name: "Case Based Checklist",
          //   to: "/Case_Based_Checklist",
          //   screen_code:  ["CREATE_CHK"],
          //   product_code: ["TECTALIK_CHCK"],
          //   // CREATE_INS
          // },
          {
            id: generateRandomString(),
            icon: "mdi-alpha-r-box",
            name: "Reports",
            to: "/showlist_all_report",
            // screen_code: ["RUN_REPORTS"],
            product_code: ["TECTALIK_CHCK"],
            screen_codeflag: ["SCN_CHCK_REPORTS"],
            module_codeflag: ["COMPLIANCE"],
          },
        ],
      },
      {
        id: generateRandomString(),
        name: "Tickets",
        // screen_code: ["CREATE_MASTERS"],
        icon: "mdi-alpha-t-circle",
        product_code: ["TECTALIK_TKT"],
        // screen_code: ["CREATE_CHK", "RUN_REPORTS"],
        child: true,
        sub_children: true,
        screen_codeflag: ["SCN_TKT_TICKETS", "SCN_TKT_REPORTS"],
        module_codeflag: ["TICKETS"],
        children: [
          {
            id: generateRandomString(),
            icon: "mdi-clipboard-edit",
            name: "Tickets",
            to: "/ticket",
            screen_code: ["CREATE_CHK"],
            product_code: ["TECTALIK_TKT"],
            screen_codeflag: ["SCN_TKT_TICKETS"],
            module_codeflag: ["TICKETS"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-alpha-s-box",
            name: "SLA score",
            to: "/SlaScore",
            screen_code: ["CREATE_CHK"],
            product_code: ["TECTALIK_TKT"],
            screen_codeflag: ["SCN_TKT_TICKETS"],
            module_codeflag: ["TICKETS"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-alpha-r-box",
            name: "Reports",
            to: "/tickets_report",
            screen_code: ["RUN_REPORTS"],
            product_code: ["TECTALIK_TKT"],
            screen_codeflag: ["SCN_TKT_REPORTS"],
            module_codeflag: ["TICKETS"],
          },
        ],
      },
      {
        id: generateRandomString(),
        name: "Walkin",
        child: true,
        sub_children: true,
        icon: "mdi-alpha-w-circle",
        screen_code: ["CREATE_CHK"],
        screen_codeflag: [
          "SCN_WALKINS",
          "SCN_WLK_RPRT",
          "SCN_WLKN_FEEDBACK_RPRT",
        ],
        product_code: ["TECTALIK_WLK"],
        module_codeflag: ["WALKINS"],
        children: [
          {
            id: generateRandomString(),
            name: "Age Master",
            child: true,
            icon: "mdi-alpha-w-circle ",
            to: "/walkins_age_master",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_WLK"],
            screen_codeflag: ["SCN_WALKINS"],
            module_codeflag: ["WALKINS"],
          },
          {
            id: generateRandomString(),
            name: "Walkins",
            child: true,
            icon: "mdi-alpha-a-circle ",
            to: "/walkin/landing",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_WLK"],
            screen_codeflag: ["SCN_WALKINS"],
            module_codeflag: ["WALKINS"],
          },
          {
            id: generateRandomString(),
            name: "Reports",
            child: true,
            icon: "mdi-alpha-s-circle ",
            to: "/walkins/reports",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_WLK"],
            screen_codeflag: ["SCN_WLK_RPRT"],
            module_codeflag: ["WALKINS"],
          },

          {
            id: generateRandomString(),
            name: "Feedback Reports",
            child: true,
            icon: "mdi-alpha-c-circle ",
            to: "/feedback/reports",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_WLK"],
            screen_codeflag: ["SCN_WLKN_FEEDBACK_RPRT"],
            module_codeflag: ["WALKINS"],
          },
        ],
      },
      {
        id: generateRandomString(),
        name: "Visits",
        child: true,
        sub_children: true,
        icon: "mdi-alpha-v-circle",
        screen_code: ["CREATE_CHK"],
        screen_codeflag: ["SCN_VISITS", "SCN_VISIT_REPORTS"],
        product_code: ["TECTALIK_VISITS"],
        module_codeflag: ["VISITS"],
        children: [
          {
            id: generateRandomString(),
            name: "Visits",
            child: true,
            icon: "mdi-alpha-v-circle ",
            to: "/visits/landing",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_VISITS"],
            screen_codeflag: ["SCN_VISITS"],
            module_codeflag: ["VISITS"],
          },
          {
            id: generateRandomString(),
            name: "Reports",
            child: true,
            icon: "mdi-alpha-r-circle",
            to: "/visitReport",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_VISITS"],
            screen_codeflag: ["SCN_VISIT_REPORTS"],
            module_codeflag: ["VISITS"],
          },
        ],
      },
      {
        id: generateRandomString(),
        name: "Attendance",
        child: true,
        sub_children: true,
        icon: "mdi-alpha-a-circle",
        screen_code: ["CREATE_CHK"],
        screen_codeflag: ["SCN_ATTENDANCE", "SCN_ATTENDANCE_REPORTS"],
        product_code: ["TECTALIK_VISITS"],
        module_codeflag: ["ATTENDANCE"],
        children: [
          {
            id: generateRandomString(),
            name: "Attendance",
            child: true,
            icon: "mdi-alpha-a-box ",
            to: "/attendance_landing",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_VISITS"],
            screen_codeflag: ["SCN_ATTENDANCE"],
            module_codeflag: ["ATTENDANCE"],
          },
          {
            id: generateRandomString(),
            name: "Attendance Reports",
            child: true,
            icon: "mdi-alpha-r-box",
            to: "/visits/attendance_report",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_VISITS"],
            screen_codeflag: ["SCN_ATTENDANCE_REPORTS"],
            module_codeflag: ["ATTENDANCE"],
          }
        ],
      },
      {
        id: generateRandomString(),
        name: "Survey",
        child: true,
        sub_children: true,
        icon: "mdi-alpha-s-circle",
        // screen_code: ["CREATE_CHK"],
        screen_codeflag: ["SCN_SURVEY", "SCN_SURVEY_REPORTS"],
        product_code: ["TECTALIK_SURVEY"],
        module_codeflag: ["SURVEYS"],
        children: [
          {
            id: generateRandomString(),
            name: "Survey",
            child: true,
            icon: "mdi-alpha-s-circle",
            to: "/survey",
            // screen_code: ["CREATE_CHK"],
            product_code: ["TECTALIK_SURVEY"],
            screen_codeflag: ["SCN_SURVEY"],
            module_codeflag: ["SURVEYS"],
          },
          {
            id: generateRandomString(),
            name: "Type of Questionnaire",
            child: true,
            icon: "mdi-alpha-s-circle",
            to: "/type_of_questionaire",
            // screen_code: ["CREATE_CHK"],
            product_code: ["TECTALIK_SURVEY"],
            screen_codeflag: ["SCN_SURVEY"],
            module_codeflag: ["SURVEYS"],
          },
          {
            id: generateRandomString(),
            name: "Reports",
            child: true,
            icon: "mdi-alpha-r-circle",
            to: "/survey_reports",
            // screen_code: ["CREATE_CHK"],
            product_code: ["TECTALIK_SURVEY"],
            screen_codeflag: ["SCN_SURVEY_REPORTS"],
            module_codeflag: ["SURVEYS"],
          },
        ],
      },
      {
        id: generateRandomString(),
        name: "workflow",
        icon: "mdi-alpha-c-circle",
        child: true,
        sub_children: true,
        // screen_code: ["CREATE_CHK", "RUN_REPORTS"],
        product_code: ["TECTALIK_CHCK"],
        screen_codeflag: [
          "SCN_CHCK_MY_CHECKLIST",
          "SCN_CHCK_CASE_BASED",
          "SCN_CHCK_REPORTS",
        ],
        module_codeflag: ["COMPLIANCE"],
        children: [
          {
            id: generateRandomString(),
            icon: "mdi-checkbox-marked-outline",
            name: "Work Flow",
            to: "/case_based_workflow",
            has_children: false,
            // screen_code: ["CREATE_CHK"],
            product_code: ["TECTALIK_CHCK"],
            screen_codeflag: ["SCN_CHCK_MY_CHECKLIST", "SCN_CHCK_CASE_BASED"],
            module_codeflag: ["COMPLIANCE"],
          },
        ],
      },
      {
        id: generateRandomString(),
        name: "Forms",
        child: true,
        sub_children: true,
        icon: "mdi-alpha-s-circle",
        // screen_code: ["CREATE_CHK"],
        screen_codeflag: ["SCN_BRANCH"],
        product_code: ["TECTALIK_CHCK"],
        module_codeflag: ["MASTERS"],
        children: [
          {
            id: generateRandomString(),
            name: "Forms",
            child: true,
            icon: "mdi-form-select",
            to: "/forms_master",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK"],
            screen_codeflag: ["SCN_BRANCH"],
            module_codeflag: ["MASTERS"],
          },
        ],
      },
      {
        id: generateRandomString(),
        name: "Meeting",
        child: true,
        sub_children: true,
        icon: "mdi-alpha-s-circle",
        // screen_code: ["CREATE_CHK"],
        screen_codeflag: ["SCN_BRANCH"],
        product_code: ["TECTALIK_CHCK"],
        module_codeflag: ["MASTERS"],
        children: [
          {
            id: generateRandomString(),
            name: "Meeting",
            child: true,
            icon: "mdi-form-select",
            to: "/meetings",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK"],
            screen_codeflag: ["SCN_BRANCH"],
            module_codeflag: ["MASTERS"],
          },
        ],
      },
    ],
  },
  
  // {
  //   id: generateRandomString(),
  //   name: "Forms",
  //   child: true,
  //   has_children: true,
  //   icon: "mdi-alpha-f-circle",
  //   screen_codeflag: ["SCN_FORMS"],
  //   product_code: ["TECTALIK_FORMS"],
  //   module_codeflag: ["FORMS"],
  //   children: [
  //     {
  //       id: generateRandomString(),
  //       name: "Forms",
  //       child: true,
  //       icon: "mdi-form-select",
  //       to: "/forms_master",
  //       screen_code: ["CREATE_MASTERS"],
  //       product_code: ["TECTALIK_FORMS"],
  //       screen_codeflag: ["SCN_FORMS"],
  //       module_codeflag: ["FORMS"],
  //     },
  //   ],
  // },
  // {
  //   id: generateRandomString(),
  //   name: "Meeting",
  //   child: true,
  //   has_children: true,
  //   icon: "mdi-alpha-m-circle",
  //   // screen_code: ["CREATE_CHK"],
  //   screen_codeflag: ["SCN_MEETING"],
  //   product_code: ["TECTALIK_MEETING"],
  //   module_codeflag: ["MEETING"],
  //   children: [
  //     {
  //       id: generateRandomString(),
  //       name: "Meeting",
  //       child: true,
  //       icon: "mdi-form-select",
  //       to: "/meetings",
  //       screen_code: ["CREATE_MASTERS"],
  //       product_code: ["TECTALIK_MEETING"],
  //       screen_codeflag: ["SCN_MEETING"],
  //       module_codeflag: ["MEETING"],
  //     },
  //   ],
  // },

  // {
  //     id: generateRandomString(),
  //     icon: "mdi-alpha-m-circle",
  //     name: "Meetings",
  //     to: "/meetings",
  //     screen_code: ["CREATE_MASTERS"],
  //     has_children: false,
  //     product_code: ["TECTALIK_ANALYTICS"],
  //     // isnonadmintoshow: true,
  //     screen_codeflag: ["SCN_ORG_CHART"],
  //     module_codeflag: ["COMMON"],

  // },

  {
    id: generateRandomString(),
    icon: "mdi-alpha-r-circle",
    name: "Review",
    // teamdrop:"yes",
    // to: "/team_list",
    has_children: true,
    screen_code: ["CREATE_MASTERS"],

    product_code: ["TECTALIK_ANALYTICS"],
    // isnonadmintoshow: true,
    screen_codeflag: All_Review,
    module_codeflag: ["COMMON"],
    children: [
      {
        id: generateRandomString(),
        icon: "mdi-alpha-a-circle",
        name: "Analytics",
        to: "/kpi_bots",
        // screen_code: ["CREATE_MASTERS"],
        has_children: false,
        product_code: ["TECTALIK_ANALYTICS"],
        // isnonadmintoshow: true,
        screen_codeflag: ["SCN_ANALYTICS"],
        module_codeflag: ["COMMON"],
      },
      //  {
      //   id: generateRandomString(),
      //   icon: "mdi-alpha-a-circle",
      //   name: "configurator",
      //   to: "/configurator",
      //   screen_code: ["CREATE_MASTERS"],
      //   has_children: false,
      //   product_code: ["TECTALIK_ANALYTICS"],
      //   // isnonadmintoshow: true,
      //   screen_codeflag: ["SCN_ORG_CHART"],
      //   module_codeflag: ["COMMON"],
      // },
      // {
      //   id: generateRandomString(),
      //   icon: "mdi-alpha-a-circle",
      //   name: "Jewellery Dashboard",
      //   to: "/jewellery",
      //   screen_code: ["CREATE_MASTERS"],
      //   has_children: false,
      //   product_code: ["TECTALIK_ANALYTICS"],
      //   // isnonadmintoshow: true,
      //   screen_codeflag: ["SCN_ORG_CHART"],
      //   module_codeflag: ["COMMON"],
      // },
      {
        id: generateRandomString(),
        name: "Analytic Solution",
        child: true,
        icon: "mdi-v-circle",
        screen_code: ["CREATE_MASTERS"],
        product_code: ["TECTALIK_ANALYTICS"],
        sub_children: true,
        grouping_children: true,
        screen_codeflag: ["SCN_JEWELLERY_DASHBOARD", "SCN_SALES_DASHBOARD"],
        module_codeflag: ["COMMON"],
        has_grand_children: false,
        children: [
          {
            id: generateRandomString(),
            name: "Inventory Optimization",
            child: true,
            icon: "mdi-alpha-i-circle",
            // to: "/jewellery",
            to: (localStorage.getItem("domain") == "alagar") ? "/jewellery_dashboard_alagar" : "/jewellery_dashboard_vbj",
            // screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_ANALYTICS"],
            screen_codeflag: ["SCN_JEWELLERY_DASHBOARD"],
            module_codeflag: ["COMMON"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-alpha-s-box",
            name: "Sales Predictor",
            to: "/sales_dashboard",
            // screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_ANALYTICS"],
            screen_codeflag: ["SCN_SALES_DASHBOARD"],
            module_codeflag: ["COMMON"],
          },
        ],
      },
    ],
  },

  {
    id: generateRandomString(),
    name: "Reward",
    icon: "mdi-alpha-r-circle",
    // to: "/score_list",
    has_children: true,
    screen_code: ["CREATE_MASTERS"],
    product_code: ["TECTALIK_PMS"],
    underline: true,
    screen_codeflag: All_Reward,
    module_codeflag: ["PERFORMANCE"],
    children: [
      // {
      //   id: generateRandomString(),
      //   icon: "mdi-account-check",
      //   name: "Assign Kpi",
      //   to: "/assign_kpi",
      //   screen_code: ["CREATE_MASTERS"],
      //   has_children : false,
      //   product_code: ["TECTALIK_PMS"],
      // },

      // {
      //     id: generateRandomString(),
      //     icon: "mdi-bullseye-arrow",
      //     name: "Set Target",
      //     to: "/set_target",
      //     screen_code: ["CREATE_MASTERS"],
      //     has_children: false,
      //     product_code: ["TECTALIK_PMS"],
      //     screen_codeflag: ["SCN_PMS_SET_TARGET"],
      //     module_codeflag: ["PERFORMANCE"],
      // },

      // {
      //     id: generateRandomString(),
      //     icon: "mdi-cash",
      //     name: "My Appraisal",
      //     to: "/profile",
      //     screen_code: ["CREATE_MASTERS"],
      //     has_children: false,
      //     product_code: ["TECTALIK_PMS"],
      //     screen_codeflag: ["SCN_PMS_SET_TARGET"],
      //     module_codeflag: ["PERFORMANCE"],
      // },
      // {
      //     id: generateRandomString(),
      //     icon: "mdi-cash-multiple",
      //     name: "My Reportees",
      //     to: "/reportees_rating_updates",
      //     screen_code: ["CREATE_MASTERS"],
      //     has_children: false,
      //     product_code: ["TECTALIK_PMS"],
      //     screen_codeflag: ["SCN_PMS_SET_TARGET"],
      //     module_codeflag: ["PERFORMANCE"],
      // },
      {
        id: generateRandomString(),
        icon: "mdi-calendar-month-outline",
        name: "Appraisals",
        screen_code: ["CREATE_MASTERS"],
        sub_children: true,
        product_code: ["TECTALIK_PMS"],
        screen_codeflag: [
          "SCN_APPRAISAL_SET_APPRAISAL_CYCLE",
          "SCN_APPRAISAL_VIEW_APPRAISAL",
          "SCN_APPRISALTRENDREPORT",
        ],
        module_codeflag: ["PERFORMANCE"],
        children: [
          // {
          //     id: generateRandomString(),
          //     icon: "mdi-calendar-month-outline",
          //     name: "Set Appraisal Cycle",
          //     to: "/appraisal_add",
          //     screen_code: ["CREATE_MASTERS"],
          //     has_children: false,
          //     product_code: ["TECTALIK_PMS"],
          //     screen_codeflag: ["SCN_APPRAISAL_SET_APPRAISAL_CYCLE"],
          //     module_codeflag: ["PERFORMANCE"],
          // },

          {
            id: generateRandomString(),
            icon: "mdi-calendar-month-outline",
            name: "My Appraisals",
            to: "/appraisal_landing",
            screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_APPRAISAL_VIEW_APPRAISAL"],
            module_codeflag: ["PERFORMANCE"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-calendar-month-outline",
            name: "My Scorecard",
            to: "/my_scorecard",
            screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_APPRAISAL_VIEW_APPRAISAL"],
            module_codeflag: ["PERFORMANCE"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-calendar-month-outline",
            name: "Appraisal Reports",
            to: "/Appraisaltrend",
            screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_APPRISALTRENDREPORT"],
            module_codeflag: ["PERFORMANCE"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-calendar-month-outline",
            name: "Target and  Actual",
            to: "/targetandactual",
            screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_TARGET_ACTUAL"],
            module_codeflag: ["PERFORMANCE"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-calendar-month-outline",
            name: "KPI Performance Report",
            to: "/cumulative_actuals_targets_report",
            screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_TARGET_ACTUAL"],
            module_codeflag: ["PERFORMANCE"],
          },
        ],
      },
      {
        id: generateRandomString(),
        icon: "mdi-gift",
        name: "Incentives",
        screen_code: ["CREATE_MASTERS"],
        sub_children: true,
        product_code: ["TECTALIK_PMS"],
        screen_codeflag: [
          "SCN_INCENTIVE_POLICY",
          "SCN_INCENTIVE_SET_FREQUENCY",
        ],
        module_codeflag: ["PERFORMANCE"],
        children: [
          {
            id: generateRandomString(),
            icon: "mdi-gift",
            name: "Set Incentive Policy",
            to: "/incentives",
            screen_code: ["CREATE_MASTERS"],
            sub_children: true,
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_INCENTIVE_POLICY"],
            module_codeflag: ["PERFORMANCE"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-calendar-month",
            name: "Payout Frequency",
            to: "/set_frequency",
            screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_INCENTIVE_SET_FREQUENCY"],
            module_codeflag: ["PERFORMANCE"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-alpha-r-box",
            name: "Incentive Reports",
            to: "/incentive_report",
            screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_INCENTIVE_POLICY"],
            module_codeflag: ["PERFORMANCE"],
          },
        ],
      },
    ],
  },

  // {
  //   id: generateRandomString(),
  //   has_children : false,
  //   onlytextviews : false,
  //   icon: "mdi-monitor-dashboard",
  //   name: "Dashboards",
  //   screen_code: ["CREATE_MASTERS"],
  //   to: "/Dashboard_checklist",
  //   product_code: ["TECTALIK_CHCK","TECTALIK_TKT","TECTALIK_PMS"],
  //   underline: false,
  //   isnonadmintoshow: true,
  // },
  {
    id: generateRandomString(),
    has_children: false,
    onlytextviews: false,
    icon: "mdi-table-check",
    name: "Library",
    to: "/checklist_Template_page",
    iscommontoshow: true,
    // screen_code: ["ADOPT_CHK"],
    product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
    underline: false,
    screen_codeflag: ["SCN_LIBRARY"],
    module_codeflag: ["COMMON"],
    // isnonadmintoshow: false,
  },

  // Admin mAsters
  {
    id: generateRandomString(),
    name: "Masters",
    icon: "mdi-alpha-m-circle",
    screen_code: ["CREATE_MASTERS", "CREATE_USERS"],
    product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
    has_children: true,
    // iscommontoshow: true,
    screen_codeflag: [
      "COMMON_SCN_CLIENT",
      "COMMON_SCN_GALLERY",
      "COMMON_SCN_PRODUCT",
      "COMMON_SCN_INDUSTRY",
      "COMMON_SCN_PROCESS",
      "COMMON_SCN_KPI_ROLES",
      "COMMON_SCN_SERVICE_CATEGORY",
      "COMMON_SCN_MODULES",
      "COMMON_SCN_BLOG"
    ],
    children: [
      {
        id: generateRandomString(),
        name: "Client",
        child: true,
        icon: "mdi-account",
        to: "/client",
        screen_codeflag: ["COMMON_SCN_CLIENT"],
        // iscommontoshow: true
      },
      {
        id: generateRandomString(),
        name: "Assets",
        child: true,
        icon: "mdi-alpha-a-circle",
        to: "/Assets",
        screen_codeflag: ["COMMON_SCN_GALLERY"],
        // iscommontoshow: true
      },
      {
        id: generateRandomString(),
        name: "Gallery",
        child: true,
        icon: "mdi-image",
        to: "/image",
        // iscommontoshow: true
        screen_codeflag: ["COMMON_SCN_GALLERY"],
      },
      {
        id: generateRandomString(),
        name: "Product",
        child: true,
        icon: "mdi-alpha-p-circle",
        to: "/product",
        screen_codeflag: ["COMMON_SCN_PRODUCT"],
        // iscommontoshow: true
      },
      {
        id: generateRandomString(),
        name: "Blog",
        child: true,
        icon: "mdi-alpha-b-circle",
        to: "/blog",
        screen_codeflag: ["COMMON_SCN_PRODUCT"],
        // iscommontoshow: true
      },
      {
        id: generateRandomString(),
        name: "Category And sectors",
        child: true,
        icon: "mdi-alpha-c-circle",
        to: "/blog_sect_categ",
        screen_codeflag: ["COMMON_SCN_PRODUCT"],
        // iscommontoshow: true
      },

      {
        id: generateRandomString(),
        name: "Industry",
        child: true,
        icon: "mdi-factory",
        to: "/Industry",
        // iscommontoshow: true,
        screen_codeflag: ["COMMON_SCN_INDUSTRY"],
        // product_code: ["TECTALIK_CHCK"],
      },
      {
        id: generateRandomString(),
        name: "Process",
        child: true,
        icon: "mdi-weather-hurricane",
        to: "/process",
        // iscommontoshow: true,
        screen_codeflag: ["COMMON_SCN_PROCESS"],
      },
      {
        id: generateRandomString(),
        name: "KPI Roles",
        child: true,
        icon: "mdi-alpha-k-circle",
        to: "/kpiroles",
        // iscommontoshow: true,
        screen_codeflag: ["COMMON_SCN_KPI_ROLES"],
      },

      {
        id: generateRandomString(),
        name: "Service Category",
        child: true,
        icon: "mdi-alpha-k-circle",
        to: "/tkt_service_category",
        // iscommontoshow: true,
        screen_codeflag: ["COMMON_SCN_SERVICE_CATEGORY"],
      },
      {
        id: generateRandomString(),
        name: "Function",
        child: true,
        icon: "mdi-monitor-dashboard",
        to: "/admin_function_landing",
        screen_codeflag: ["COMMON_SCN_SERVICE_CATEGORY"],
      },
      {
        id: generateRandomString(),
        name: "Skills Group",
        child: true,
        icon: "mdi-select-group",
        to: "/admin_skills_group_landing",
        screen_codeflag: ["COMMON_SCN_SERVICE_CATEGORY"],
      },
      {
        id: generateRandomString(),
        name: "Competencies",
        child: true,
        icon: "mdi-group",
        to: "/admin_competencies_landing",
        screen_codeflag: ["COMMON_SCN_SERVICE_CATEGORY"],
      },
      {
        id: generateRandomString(),
        name: "Department",
        child: true,
        icon: "mdi-bus-clock",
        to: "/admin_dept_landing",
        screen_codeflag: ["COMMON_SCN_SERVICE_CATEGORY"],
      },
      {
        id: generateRandomString(),
        name: "Designation",
        child: true,
        icon: "mdi-altimeter",
        to: "/admin_desig_landing",
        screen_codeflag: ["COMMON_SCN_SERVICE_CATEGORY"],
      },
      {
        id: generateRandomString(),
        name: "Role Assignments",
        child: true,
        icon: "mdi-altimeter",
        to: "/admin_role_assignments_group",
        screen_codeflag: ["COMMON_SCN_SERVICE_CATEGORY"],
      },
      {
        id: generateRandomString(),
        name: "Modules",
        child: true,
        icon: "mdi-alpha-m-box",
        to: "/modules",
        // iscommontoshow: true,
        screen_codeflag: ["COMMON_SCN_MODULES"],
      },
      // {
      //   id: generateRandomString(),
      //   name: "subscription",
      //   child: true,
      //   icon: "mdi-alpha-m-box",
      //   to: "/subscription",
      //   // iscommontoshow: true,
      //   screen_codeflag: ["COMMON_SCN_MODULES"],
      // },
      {
        id: generateRandomString(),
        name: "Lead Source",
        child: true,
        icon: "mdi-alpha-s-box",
        to: "/lead_source_landing",
        // iscommontoshow: true,
        screen_codeflag: ["COMMON_SCN_MODULES"],
      },
      {
        id: generateRandomString(),
        name: "Lead Campaign",
        child: true,
        icon: "mdi-alpha-c-box",
        to: "/campaign_landing",
        // iscommontoshow: true,
        screen_codeflag: ["COMMON_SCN_MODULES"],
      },
      {
        id: generateRandomString(),
        name: "Lead",
        child: true,
        icon: "mdi-alpha-m-box",
        to: "/lead_landing",
        // iscommontoshow: true,
        screen_codeflag: ["COMMON_SCN_MODULES"],
      },
      // {
      //   id: generateRandomString(),
      //   name: "Discount",
      //   child: true,
      //   icon: "mdi-alpha-d-box",
      //   to: "/discount_mstr",
      //   // iscommontoshow: true,
      //   screen_codeflag: ["COMMON_SCN_MODULES"],
      // },
      {
        id: generateRandomString(),
        name: "Financial Benachmarking",
        child: true,
        icon: "mdi-alpha-d-box",
        to: "/bench_marking",
        // iscommontoshow: true,
        screen_codeflag: ["COMMON_SCN_MODULES"],
      },
    ],
  },
  {
    id: generateRandomString(),
    name: "Users",
    child: true,
    icon: "mdi-account-box-multiple-outline",
    screen_code: ["CREATE_USERS"],
    product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
    has_children: true,
    // iscommontoshow: true,
    screen_codeflag: ["COMMON_SCN_USERS", "COMMON_SCN_USERS_TYPE"],

    children: [
      {
        id: generateRandomString(),
        name: "User",
        child: true,
        icon: "mdi-account-cog-outline",
        to: "/Common_user",
        // iscommontoshow: true,
        screen_codeflag: ["COMMON_SCN_USERS"],
      },
      {
        id: generateRandomString(),
        name: "User Type",
        child: true,
        icon: "mdi-alpha-u-circle",
        to: "/user_type",
        // iscommontoshow: true,
        screen_codeflag: ["COMMON_SCN_USERS_TYPE"],
      },
      {
        id: generateRandomString(),
        name: "Partner",
        child: true,
        icon: "mdi-alpha-p-circle",
        to: "/partner",
        // iscommontoshow: true,
        screen_codeflag: ["COMMON_SCN_USERS"],
      },
    ],
  },
  // {
  //     id: generateRandomString(),
  //     name: "Dashboard",
  //     icon: "mdi-alpha-d-box",
  //     child: true,
  //     screen_code: ["CREATE_DASHBOARD"],
  //     product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
  //     has_children: true,
  //     // iscommontoshow: true,
  //     screen_codeflag: ["COMMON_SCN_DASHBOARD"],
  //         children: [{
  //             id: generateRandomString(),
  //             name: "ORO",
  //             child: true,
  //             icon: "mdi-alpha-o-box",
  //             to: "/dashboard_landing",
  //             screen_codeflag: ["COMMON_SCN_DASHBOARD"],

  //         }, ]
  // },
  //

  // {
  //     id: generateRandomString(),
  //     name: "Actual",
  //     child: true,
  //     screen_code: ["CREATE_MASTERS"],
  //     product_code: ["TECTALIK_PMS"],
  //     has_children: true,
  //     screen_codeflag: ["SCN_ACTUAL"],
  //     module_codeflag: ["ACTUAL"],
  //     icon: "mdi-cog-outline",
  //     children: [
  // {
  //             id: generateRandomString(),
  //             name: "Actual",
  //             child: true,
  //             icon: "mdi-alpha-b-circle",
  //             to: "/actual",
  //             screen_code: ["CREATE_MASTERS"],
  //             product_code: ["TECTALIK_PMS"],
  //             screen_codeflag: ["SCN_ACTUAL"],
  //             module_codeflag: ["ACTUAL"],
  //         },
  //     ]
  // },

  // {
  //     id: generateRandomString(),
  //     has_children: false,
  //     onlytextviews: false,
  //     icon: "mdi-account",
  //     screen_code: ["CREATE_MASTERS"],
  //     name: "My Profile",
  //     to: "/My_profile",
  //     product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
  //     underline: true,
  //     isnonadmintoshow: true,
  //     screen_codeflag: ["SCN_MY_PROFILE"],
  //     module_codeflag: ["COMMON"],
  // },

  // {
  //     id: generateRandomString(),
  //     has_children: false,
  //     onlytextviews: true,
  //     icon: "mdi-account",
  //     screen_code: ["CREATE_MASTERS"],
  //     name: localStorage.getItem("USER_NAME") + "'s Space",
  //     product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
  //     underline: false,
  //     isnonadmintoshow: true,
  //     hide_rights: true,

  // },
  {
    id: generateRandomString(),
    has_children: false,
    onlytextviews: false,
    icon: "mdi-table-check",
    name: "Library",
    to: "/checklist_Template_page",
    iscommontoshow: false,
    screen_code: ["ADOPT_CHK"],
    product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
    underline: false,
    isnonadmintoshow: false,
    screen_codeflag: ["SCN_LIBRARY"],
    module_codeflag: ["COMMON"],
  },
  {
    id: generateRandomString(),
    name: "Masters",
    icon: "mdi-alpha-m-circle",
    screen_code: ["CREATE_MASTERS"],
    product_code: [
      "TECTALIK_CHCK",
      "TECTALIK_TKT",
      "TECTALIK_PMS",
      "TECTALIK_PLANNING",
      "TECTALIK_VISITS",
    ],
    has_children: true,
    screen_codeflag: allMaster,
    module_codeflag: ["MASTERS"],
    // isnonadmintoshow: true,
    children: [
      {
        id: generateRandomString(),
        name: "Global",
        child: true,
        icon: "mdi-monitor-dashboard",
        screen_code: ["CREATE_MASTERS"],
        product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
        sub_children: true,
        has_grand_children: false,
        screen_codeflag: CommoNMaster,
        module_codeflag: ["MASTERS"],
        children: [
          {
            id: generateRandomString(),
            name: "Branch",
            child: true,
            icon: "mdi-monitor-dashboard",
            to: "/branch",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
            screen_codeflag: ["SCN_BRANCH"],
            module_codeflag: ["MASTERS"],
          },
          {
            id: generateRandomString(),
            name: "Department",
            child: true,
            icon: "mdi-bus-clock",
            to: "/department",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
            screen_codeflag: ["SCN_DEPARTMENT"],
            module_codeflag: ["MASTERS"],
          },

          {
            id: generateRandomString(),
            name: "Segment",
            child: true,
            icon: "mdi-alpha-d-circle ",
            to: "/segment",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
            screen_codeflag: ["SCN_DEPARTMENT"],
            module_codeflag: ["MASTERS"],
          },
          {
            id: generateRandomString(),
            name: "Skills Group",
            child: true,
            icon: "mdi-select-group",
            to: "/skillsgroup",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
            screen_codeflag: ["SCN_FUNCTION"],
            module_codeflag: ["MASTERS"],
          },
          {
            id: generateRandomString(),
            name: "Competencies",
            child: true,
            icon: "mdi-group",
            to: "/competencies",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
            screen_codeflag: ["SCN_FUNCTION"],
            module_codeflag: ["MASTERS"],
          },
          {
            id: generateRandomString(),
            name: "Function",
            child: true,
            icon: "mdi-format-list-group-plus",
            to: "/function",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
            screen_codeflag: ["SCN_FUNCTION"],
            module_codeflag: ["MASTERS"],
          },
          {
            id: generateRandomString(),
            name: "Designation",
            child: true,
            icon: "mdi-altimeter",
            to: "/designation",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
            screen_codeflag: ["SCN_DESIGNATION"],
            module_codeflag: ["MASTERS"],
          },
          {
            id: generateRandomString(),
            name: "Delegation",
            child: true,
            icon: "mdi-gavel",
            to: "/Delegation",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
            screen_codeflag: ["SCN_DELEGATION"],
            module_codeflag: ["DOA"],
          },
          {
            id: generateRandomString(),
            name: "Employee Grade",
            child: true,
            icon: "mdi-account-badge",
            to: "/employee_grade",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
            screen_codeflag: ["SCN_EMP_GRADE"],
            module_codeflag: ["MASTERS"],
          },
          // {
          //     id: generateRandomString(),
          //     name: "Modules",
          //     child: true,
          //     icon: "mdi-alpha-m-box",
          //     to: "/modules",
          //     screen_code: ["CREATE_MASTERS"],
          //     product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
          //     screen_codeflag: ["SCN_DESIGNATION"],
          //     module_codeflag: ["MASTERS"],
          // },
          {
            id: generateRandomString(),
            name: "Employee",
            child: true,
            icon: "mdi-account-clock",
            to: "/employee",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
            screen_codeflag: ["SCN_EMPLOYEE"],
            module_codeflag: ["MASTERS"],
          },
          // {
          //     id: generateRandomString(),
          //     name: "Customer",
          //     child: true,
          //     icon: "mdi-monitor-dashboard",
          //     to: "/customer",
          //     screen_code: ["CREATE_MASTERS"],
          //     product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
          //     screen_codeflag: ["SCN_BRANCH"],
          //     module_codeflag: ["MASTERS"],
          // },
          {
            id: generateRandomString(),
            name: "Sub Department",
            child: true,
            icon: "mdi-account-clock",
            to: "/subdepartment",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
            screen_codeflag: ["SCN_SUB_DEPARTMENT"],
            module_codeflag: ["MASTERS"],
          },

          {
            id: generateRandomString(),
            name: "Region",
            child: true,
            icon: "mdi-clipboard-edit",
            to: "/region",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
            screen_codeflag: ["SCN_REGION"],
            module_codeflag: ["MASTERS"],
          },
          {
            id: generateRandomString(),
            name: "Sub Region",
            child: true,
            icon: "mdi-clipboard-edit",
            to: "/subregion/landing",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
            screen_codeflag: ["SCN_SUBREGION"],
            module_codeflag: ["MASTERS"],
          },
          {
            id: generateRandomString(),
            name: "Business Type",
            child: true,
            icon: "mdi-clipboard-edit",
            to: "/business_type/landing",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
            screen_codeflag: ["SCN_BUSINESS_TYPE"],
            module_codeflag: ["MASTERS"],
          },
          {
            id: generateRandomString(),
            name: "Zone",
            child: true,
            icon: "mdi-clipboard-edit",
            to: "/zone/landing",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
            screen_codeflag: ["SCN_ZONE"],
            module_codeflag: ["MASTERS"],
          },
          {
            id: generateRandomString(),
            name: "District",
            child: true,
            icon: "mdi-clipboard-edit",
            to: "/district/landing",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
            screen_codeflag: ["SCN_DISTRICT"],
            module_codeflag: ["MASTERS"],
          },
          {
            id: generateRandomString(),
            name: "Outlet",
            child: true,
            icon: "mdi-clipboard-edit",
            to: "/outlet/landing",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
            screen_codeflag: ["SCN_OUTLET"],
            module_codeflag: ["MASTERS"],
          },
          {
            id: generateRandomString(),
            name: "Employee Role",
            child: true,
            icon: "mdi-altimeter",
            to: "/distinct_role_assignments",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
            screen_codeflag: ["SCN_ROLE_ASSIGNMENT_FUNCTIONS"],
            module_codeflag: ["MASTERS"],
          },
          // {
          //     id: generateRandomString(),
          //     name: "Process",
          //     child: true,
          //     icon: "mdi-weather-hurricane",
          //     to: "/process_mstr",
          //     screen_code: ["CREATE_MASTERS"],
          //     product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
          //     screen_codeflag: ["SCN_PROCESS"],
          //     module_codeflag: ["MASTERS"],
          // },
        ],
      },
      {
        id: generateRandomString(),
        name: "Checklist",
        child: true,
        icon: "mdi-monitor-dashboard",
        screen_code: ["CREATE_MASTERS"],
        product_code: ["TECTALIK_CHCK"],
        sub_children: true,
        has_grand_children: false,
        screen_codeflag: CHCKMaster,
        module_codeflag: ["MASTERS"],
        children: [
          {
            id: generateRandomString(),
            name: "Holiday",
            child: true,
            icon: "mdi-alpha-h-circle",
            to: "/holiday",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK"],
            screen_codeflag: [],
            module_codeflag: ["MASTERS"],
          },
          {
            id: generateRandomString(),
            name: "Weekoff",
            child: true,
            icon: "mdi-alpha-w-circle",
            to: "/weekoff",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK"],
            screen_codeflag: [],
            module_codeflag: ["MASTERS"],
          },
        ],
      },

      {
        id: generateRandomString(),
        name: "Ticket",
        child: true,
        icon: "mdi-monitor-dashboard",
        screen_code: ["CREATE_MASTERS"],
        product_code: ["TECTALIK_TKT"],
        sub_children: true,
        has_grand_children: false,
        screen_codeflag: TKTMaster,
        module_codeflag: ["MASTERS"],
        children: [
          {
            id: generateRandomString(),
            name: "Service Category",
            child: true,
            icon: "mdi-table-check",
            to: "/serviceCategory",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_TKT"],
            screen_codeflag: ["SCN_SERVICE_CATEGORY"],
            module_codeflag: ["MASTERS"],
          },
          {
            id: generateRandomString(),
            name: "Service",
            child: true,
            icon: "mdi-table-check",
            to: "/service",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_TKT"],
            screen_codeflag: ["SCN_SERVICE"],
            module_codeflag: ["MASTERS"],
          },
        ],
      },
      {
        id: generateRandomString(),
        name: "Performance",
        child: true,
        icon: "mdi-monitor-dashboard",
        screen_code: ["CREATE_MASTERS"],
        product_code: ["TECTALIK_PMS"],
        sub_children: true,
        has_grand_children: false,
        screen_codeflag: PMSMaster,
        module_codeflag: ["MASTERS"],
        children: [
          {
            id: generateRandomString(),
            name: "Uom",
            child: true,
            icon: "mdi-alpha-u-circle ",
            to: "/Uom",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_UOM"],
            module_codeflag: ["MASTERS"],
          },
          {
            id: generateRandomString(),
            name: "Kpi Type",
            child: true,
            icon: "mdi-alpha-k-circle ",
            to: "/kpi_type",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_KPI_TYPE"],
            module_codeflag: ["MASTERS"],
          },
          {
            id: generateRandomString(),
            name: "Target Type",
            child: true,
            icon: "mdi-alpha-t-circle",
            to: "/Target_type",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_TARGET_TYPE"],
            module_codeflag: ["MASTERS"],
          },
          {
            id: generateRandomString(),
            name: "Channel",
            child: true,
            icon: "mdi-alpha-c-box",
            to: "/Channel",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_CHANNEL"],
            module_codeflag: ["MASTERS"],
          },
          {
            id: generateRandomString(),
            name: "Subchannel",
            child: true,
            icon: "mdi-alpha-s-circle ",
            to: "/Subchannel",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_SUB_CHANNEL"],
            module_codeflag: ["MASTERS"],
          },
          {
            id: generateRandomString(),
            name: "Division",
            child: true,
            icon: "mdi-alpha-d-circle ",
            to: "/Division",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_DIVISION"],
            module_codeflag: ["MASTERS"],
          },
          {
            id: generateRandomString(),
            name: "Subdivision",
            child: true,
            icon: "mdi-alpha-s-circle ",
            to: "/Subdivision",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_SUB_DIVISION"],
            module_codeflag: ["MASTERS"],
          },

          {
            id: generateRandomString(),
            name: "Category",
            child: true,
            icon: "mdi-alpha-c-circle",
            to: "/category",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_CATEGORY"],
            module_codeflag: ["MASTERS"],
          },
          // {
          //   id: generateRandomString(),
          //   name: "Design",
          //   child: true,
          //   icon: "mdi-alpha-d-circle",
          //   to: "/design",
          //   screen_code: ["CREATE_MASTERS"],
          //   product_code: ["TECTALIK_PMS"],
          //   screen_codeflag: ["SCN_CATEGORY"],
          //   module_codeflag: ["MASTERS"],
          // },
          {
            id: generateRandomString(),
            name: "Store",
            child: true,
            icon: "mdi-basket",
            to: "/store",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_STORE"],
            module_codeflag: ["MASTERS"],
          },
          {
            id: generateRandomString(),
            name: "Performance Rating",
            child: true,
            icon: "mdi-star-check-outline",
            to: "/performance_rating",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_PERFORMANCE_RATING"],
            module_codeflag: ["MASTERS"],
          },
          {
            id: generateRandomString(),
            name: "Payroll",
            child: true,
            icon: "mdi-monitor-dashboard",
            to: "/incentive",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
            screen_codeflag: ["SCN_PAYROLL"],
            module_codeflag: ["MASTERS"],
          },

          {
            id: generateRandomString(),
            icon: "mdi-calendar-month-outline",
            name: "View Appraisals",
            to: "/appraisal_launch",
            screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_APPRAISAL_VIEW_APPRAISAL"],
            module_codeflag: ["MASTERS"],
          },
        ],
      },
      {
        id: generateRandomString(),
        name: "Walkins",
        child: true,
        icon: "mdi-w-circle",
        screen_code: ["CREATE_MASTERS"],
        product_code: ["TECTALIK_WLK"],
        sub_children: true,
        has_grand_children: false,
        screen_codeflag: WLKMaster,
        module_codeflag: ["MASTERS"],
        children: [
          {
            id: generateRandomString(),
            name: "Occasion",
            child: true,
            icon: "mdi-alpha-o-circle",
            to: "/occasion/landing",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_WLK"],
            screen_codeflag: ["SCN_WALKIN_OCCASION"],
            module_codeflag: ["MASTERS"],
          },
          {
            id: generateRandomString(),
            name: "Campaign",
            child: true,
            icon: "mdi-alpha-c-circle",
            to: "/wlk_campaign",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_WLK"],
            screen_codeflag: ["SCN_WALKIN_CAMPAIGN"],
            module_codeflag: ["MASTERS"],
          },
        ],
      },
      {
        id: generateRandomString(),
        name: "Visits",
        child: true,
        icon: "mdi-v-circle",
        screen_code: ["CREATE_MASTERS"],
        product_code: ["TECTALIK_VISITS"],
        sub_children: true,
        has_grand_children: false,
        screen_codeflag: VSTMaster,
        module_codeflag: ["MASTERS"],
        children: [
          {
            id: generateRandomString(),
            name: "Type Of Visit",
            child: true,
            icon: "mdi-monitor-dashboard",
            to: "/visittype",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_VISITS"],
            screen_codeflag: ["SCN_VISIT_TYPE"],
            module_codeflag: ["VISITS"],
          },
          {
            id: generateRandomString(),
            name: "Customer",
            child: true,
            icon: "mdi-alpha-c-circle",
            to: "/customer",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_VISITS"],
            screen_codeflag: ["SCN_VISIT_CUSTOMER"],
            module_codeflag: ["VISITS"],
          },
          {
            id: generateRandomString(),
            name: "Purpose Of Meeting",
            child: true,
            icon: "mdi-alpha-p-circle",
            to: "/PurposeOfMeeting",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_VISITS"],
            screen_codeflag: ["SCN_VISIT_POM"],
            module_codeflag: ["VISITS"],
          },
        ],
      },
      {
        id: generateRandomString(),
        name: "Business Plan",
        child: true,
        icon: "mdi-v-circle",
        screen_code: ["CREATE_MASTERS"],
        product_code: ["TECTALIK_PLANNING"],
        sub_children: true,
        grouping_children: true,
        screen_codeflag: BPMaster,
        module_codeflag: ["PLANNING"],
        has_grand_children: false,
        children: [
          {
            id: generateRandomString(),
            name: "Objective",
            child: true,
            icon: "mdi-alpha-o-circle",
            to: "/objective",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_PLANNING"],
            screen_codeflag: ["SCN_OBJECTIVE"],
            module_codeflag: ["PLANNING"],
          },
          {
            id: generateRandomString(),
            name: "Strategy group",
            child: true,
            icon: "mdi-alpha-g-circle",
            to: "/strategy",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_PLANNING"],
            screen_codeflag: ["SCN_MST_STRATEGY_GROUP"],
            module_codeflag: ["PLANNING"],
          },
        ],
      },
      {
        id: generateRandomString(),
        name: "Analytics",
        child: true,
        icon: "mdi-v-circle",
        screen_code: ["CREATE_MASTERS"],
        product_code: ["TECTALIK_PLANNING"],
        sub_children: true,
        grouping_children: true,
        screen_codeflag: BPMaster,
        module_codeflag: ["PLANNING"],
        has_grand_children: false,
        children: [
          {
            id: generateRandomString(),
            name: "Weight Range",
            child: true,
            icon: "mdi-alpha-g-circle",
            to: "/weight_range",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_PLANNING"],
            screen_codeflag: ["SCN_MST_STRATEGY_GROUP"],
            module_codeflag: ["PLANNING"],
          },
        ],
      },
      {
        id: generateRandomString(),
        name: "FMS",
        child: true,
        icon: "mdi-f-circle",
        screen_code: ["CREATE_MASTERS"],
        product_code: ["TECTALIK_FMS"],
        screen_codeflag: FMSMaster,
        sub_children: true,
        has_grand_children: true,
        module_codeflag: ["MASTERS"],
        children: [
          {
            id: generateRandomString(),
            name: "Global",
            icon: "mdi-alpha-g-circle",
            child: true,
            sub_children: true,
            grouping_children: true,
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: FMSMaster,
            module_codeflag: ["MASTERS"],
            children: [
              {
                id: generateRandomString(),
                name: "Area",
                child: true,
                icon: "mdi-alpha-a-circle",
                to: "/area_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_AREA"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Team",
                child: true,
                icon: "mdi-alpha-a-circle",
                to: "/team",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_TEAM"],
                module_codeflag: ["FMS"],
              },
              // {
              //   id: generateRandomString(),
              //   name: "Landing Display",
              //   child: true,
              //   icon: "mdi-alpha-l-circle",
              //   to: "/landing_display_mstr",
              //   screen_code: ["CREATE_MASTERS"],
              //   product_code: ["TECTALIK_FMS"],
              //   screen_codeflag: ["SCN_FMS_LANDING_DISPLAY"],
              //   module_codeflag: ["FMS"],
              // },
              {
                id: generateRandomString(),
                name: "Expertise Level",
                child: true,
                icon: "mdi-alpha-e-circle",
                to: "/expertise_level",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_EXPERTISE_LEVEL"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Route",
                child: true,
                icon: "mdi-alpha-r-circle",
                to: "/route_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_ROUTE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "State",
                child: true,
                icon: "mdi-alpha-s-circle",
                to: "/state_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_STATE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Tax",
                child: true,
                icon: "mdi-alpha-t-circle",
                to: "/tax_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_TAX"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Location",
                child: true,
                icon: "mdi-alpha-l-circle",
                to: "/location_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_LOCATION"],
                module_codeflag: ["FMS"],
              },
             
            ],
          },

          {
            id: generateRandomString(),
            name: "Lifts",
            icon: "mdi-alpha-l-circle",
            child: true,
            sub_children: true,
            grouping_children: true,
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: FMSMaster,
            module_codeflag: ["MASTERS"],
            children: [
              {
                id: generateRandomString(),
                name: "Car Operator Make",
                child: true,
                icon: "mdi-alpha-c-circle",
                to: "/caroprtr_make_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_CAR_OPERATOR_MAKE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Controller",
                child: true,
                icon: "mdi-alpha-c-circle",
                to: "/controller_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_CONTROLLER"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Call Function Type",
                child: true,
                icon: "mdi-alpha-c-circle",
                to: "/callFunctionType",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_CALL_FUNCTION_TYPE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Drive Type",
                child: true,
                icon: "mdi-alpha-d-circle",
                to: "/drive_type_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_DRIVE_TYPE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Doordrive Make",
                child: true,
                icon: "mdi-alpha-d-circle",
                to: "/doordrive_make_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_DOORDRIVE_TYPE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Encoder Model",
                child: true,
                icon: "mdi-alpha-e-circle",
                to: "/encoder_model_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_ENCODER_MODEL"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Encoder Make",
                child: true,
                icon: "mdi-alpha-e-circle",
                to: "/encoder_make_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_ENCODER_MAKE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Hoistway Landing Type",
                child: true,
                icon: "mdi-alpha-h-circle",
                to: "/highway_landing_type_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_HIGHWAY_LANDING_TYPE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Inverter Make",
                child: true,
                icon: "mdi-alpha-i-circle",
                to: "/inverter_make_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_INVERTER_MAKE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Instrument Type",
                child: true,
                icon: "mdi-alpha-i-circle",
                to: "/instrument_type_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_INSTRUMENT_TYPE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Lift Category",
                child: true,
                icon: "mdi-alpha-l-circle",
                to: "/lift_category_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_LIFT_CATEGORY"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Lift Type",
                child: true,
                icon: "mdi-alpha-l-circle",
                to: "/lift_type_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_LIFT_TYPE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Lift Make",
                child: true,
                icon: "mdi-alpha-l-circle",
                to: "/lift_make_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_LIFT_MAKE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Maindrive Make",
                child: true,
                icon: "mdi-alpha-m-circle",
                to: "/maindrive_make_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_MAINDRIVE_MAKE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Machgear Make",
                child: true,
                icon: "mdi-alpha-m-circle",
                to: "/machgear_make_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_MACHGEAR_MAKE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Motor Make",
                child: true,
                icon: "mdi-alpha-m-circle",
                to: "/motor_make_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_MOTOR_MAKE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Machine Room Position",
                child: true,
                icon: "mdi-alpha-m-circle",
                to: "/machineRoomPosition",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_MACHINE_ROOM"],
                module_codeflag: ["FMS"],
              },
            ],
          },
          {
            id: generateRandomString(),
            name: "Contracts",
            icon: "mdi-alpha-c-circle",
            child: true,
            sub_children: true,
            grouping_children: true,
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: FMSMaster,
            module_codeflag: ["MASTERS"],
            children: [
              {
                id: generateRandomString(),
                name: "Amc Cancel Category",
                child: true,
                icon: "mdi-alpha-a-circle",
                to: "/amc_cancel_category_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_AMC_CANCEL_CAT"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Amc Type",
                child: true,
                icon: "mdi-alpha-a-circle",
                to: "/amc_type_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_AMC_TYPE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Bank",
                child: true,
                icon: "mdi-alpha-b-circle",
                to: "/bank_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_BANK"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Cancellation Reason",
                child: true,
                icon: "mdi-alpha-c-circle",
                to: "/reason",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_CANCELLATION_REASON"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Deductions",
                child: true,
                icon: "mdi-alpha-d-circle",
                to: "/deduction",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_DEDUCTION"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Esclation Time",
                child: true,
                icon: "mdi-alpha-e-circle",
                to: "/esclation_time_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_ESCLATION_TIME"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Esclation Percentage",
                child: true,
                icon: "mdi-alpha-e-circle",
                to: "/esclation_percentage_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_ESCLATION_PER"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Payment Type",
                child: true,
                icon: "mdi-alpha-l-circle",
                to: "/payment_type_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_PAYMENT_TYPE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Payment Term",
                child: true,
                icon: "mdi-alpha-l-circle",
                to: "/payment_term_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_PAYMENT_TERM"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Payment Received",
                child: true,
                icon: "mdi-alpha-p-circle",
                to: "/payment_received_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_PAYMENT_RECEIVED"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Policy Type",
                child: true,
                icon: "mdi-alpha-l-circle",
                to: "/policy_type_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_POLICY_TYPE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Receipt Mode",
                child: true,
                icon: "mdi-alpha-r-circle",
                to: "/receipt_mode_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_RECEIPT_MODE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Receipt Narrations",
                child: true,
                icon: "mdi-alpha-r-circle",
                to: "/receipt_narrations",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_RECEIPT_NARRATIONS"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Reference number Type",
                child: true,
                icon: "mdi-alpha-r-circle",
                to: "/refno_type_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_REFNO_TYPE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Renewal Type",
                child: true,
                icon: "mdi-alpha-r-circle",
                to: "/renewal_type_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_RENEWAL_TYPE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Schedule Visit",
                child: true,
                icon: "mdi-alpha-s-circle",
                to: "/schedule_visit_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_SCH_VISIT"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Service",
                child: true,
                icon: "mdi-alpha-s-circle",
                to: "/fmsservice",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_SERVICE"],
                module_codeflag: ["FMS"],
              },
            ],
          },
          {
            id: generateRandomString(),
            name: "Customer",
            icon: "mdi-alpha-c-circle",
            child: true,
            sub_children: true,
            grouping_children: true,
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: FMSMaster,
            module_codeflag: ["MASTERS"],
            children: [
              {
                id: generateRandomString(),
                name: "Building Type",
                child: true,
                icon: "mdi-alpha-b-circle",
                to: "/building_type_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_BUILDING_TYPE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Customer Type",
                child: true,
                icon: "mdi-alpha-c-circle",
                to: "/client_type_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_CLIENT_TYPE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Customer Subtype",
                child: true,
                icon: "mdi-alpha-c-circle",
                to: "/client_subtype_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_CLIENT_SUBTYPE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Customer Group",
                child: true,
                icon: "mdi-alpha-c-circle",
                to: "/customer_group_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_CUSTOMER_GROUP"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Customer Designation",
                child: true,
                icon: "mdi-alpha-c-circle",
                to: "/customer_designation",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_CUSTOMER_DESIGNATION"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Customer Grade",
                child: true,
                icon: "mdi-alpha-c-circle",
                to: "/customer_grade_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_CUSTOMER_GRADE"],
                module_codeflag: ["FMS"],
              },
            ],
          },
          {
            id: generateRandomString(),
            name: "Material",
            icon: "mdi-alpha-c-circle",
            child: true,
            sub_children: true,
            grouping_children: true,
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: FMSMaster,
            module_codeflag: ["MASTERS"],
            children: [
              {
                id: generateRandomString(),
                name: "Material",
                child: true,
                icon: "mdi-alpha-m-circle",
                to: "/material_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_MATERIAL"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Spare Parts Group",
                child: true,
                icon: "mdi-alpha-s-circle",
                to: "/spare_parts_master",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_SPARE_PARTS"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Spare Category",
                child: true,
                icon: "mdi-alpha-s-circle",
                to: "/spare_group_master",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_SPARE_GROUP"],
                module_codeflag: ["FMS"],
              },
            ],
          },
          {
            id: generateRandomString(),
            name: "Tickets",
            icon: "mdi-alpha-t-circle",
            child: true,
            sub_children: true,
            grouping_children: true,
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: FMSMaster,
            module_codeflag: ["MASTERS"],
            children: [
              {
                id: generateRandomString(),
                name: "Ticket Category",
                child: true,
                icon: "mdi-alpha-t-circle",
                to: "/ticket_category_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_TKT_CAT"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Ticket Sub Category",
                child: true,
                icon: "mdi-alpha-t-circle",
                to: "/ticket_subcategory_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_TKT_SUBCAT"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Pause Category",
                child: true,
                icon: "mdi-alpha-p-circle",
                to: "/pause_category",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_PAUSE_CAT"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Customer comment",
                child: true,
                icon: "mdi-alpha-c-circle",
                to: "/customer_comment",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_CUSTOMER_COMMENT"],
                module_codeflag: ["FMS"],
              },
            ],
          },
          {
            id: generateRandomString(),
            name: "MR",
            icon: "mdi-alpha-m-circle",
            child: true,
            sub_children: true,
            grouping_children: true,
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: FMSMaster,
            module_codeflag: ["MASTERS"],
            children: [
              {
                id: generateRandomString(),
                name: "Business Center",
                child: true,
                icon: "mdi-alpha-b-circle",
                to: "/business_centre",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_BUSINESS_CENTER"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Warehouse",
                child: true,
                icon: "mdi-alpha-w-circle",
                to: "/warehouse_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_WAREHOUSE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Currency Setting",
                child: true,
                icon: "mdi-alpha-c-circle",
                to: "/currency_setting",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_CURRENCY_SETTING"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Item Category",
                child: true,
                icon: "mdi-alpha-i-circle",
                to: "/item_category_landing",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_ITEM_CATEGORY"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Item Classification",
                child: true,
                icon: "mdi-alpha-i-circle",
                to: "/item_class_landing",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_ITEM_CLASSIFICATION"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "HS Code",
                child: true,
                icon: "mdi-alpha-h-circle",
                to: "/hs_code_landing",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_HS_CODE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "UOM",
                child: true,
                icon: "mdi-alpha-u-circle",
                to: "/Uom",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_UOM"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Item Attribute",
                child: true,
                icon: "mdi-alpha-i-circle",
                to: "/item_attribute_lookup",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_ITEM_ATTRIBUTE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Item Attribute Type",
                child: true,
                icon: "mdi-alpha-i-circle",
                to: "/item_attribute_type",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_ITEM_ATTRIBUTE_TYPE"],
                module_codeflag: ["FMS"],
              },
              // {
              //   id: generateRandomString(),
              //   name: "Item Shelf Life",
              //   child: true,
              //   icon: "mdi-alpha-s-circle",
              //   to: "/shelf_life_landing",
              //   screen_code: ["CREATE_MASTERS"],
              //   product_code: ["TECTALIK_FMS"],
              //   screen_codeflag: ["SCN_FMS_ITEM_SHELF_LIFE"],
              //   module_codeflag: ["FMS"],
              // },
              {
                id: generateRandomString(),
                name: "Item type",
                child: true,
                icon: "mdi-alpha-i-circle",
                to: "/item_type_landing",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_ITEM_TYPE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Item",
                child: true,
                icon: "mdi-alpha-i-circle",
                to: "/item_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_ITEM"],
                module_codeflag: ["FMS"],
              },
             
              {
                id: generateRandomString(),
                name: "Reason For Requirement",
                child: true,
                icon: "mdi-alpha-r-circle",
                to: "/reason_for_requirement",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_REASON_FOR_REQUIREMENT"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Price Master",
                child: true,
                icon: "mdi-alpha-p-circle",
                to: "/price_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_PRICE_MASTER"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Vendor Master",
                child: true,
                icon: "mdi-alpha-v-circle",
                to: "/vendor_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_VENDOR_MASTER"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "MR Type",
                child: true,
                icon: "mdi-alpha-t-circle",
                to: "/mr_type_landing",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_MR_TYPE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Root Cause Master",
                child: true,
                icon: "mdi-alpha-r-circle",
                to: "/root_cause_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_ROOT_CAUSE_MASTER"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Mode of Transport Master",
                child: true,
                icon: "mdi-alpha-m-circle",
                to: "/mode_of_transport_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_MODE_OF_TRANSPORT_MASTER"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                name: "Action Taken Master",
                child: true,
                icon: "mdi-alpha-a-circle",
                to: "/action_taken_mstr",
                screen_code: ["CREATE_MASTERS"],
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_ACTION_TAKEN_MASTER"],
                module_codeflag: ["FMS"],
              },
            ],
          },
          // {
          //   id: generateRandomString(),
          //   name: "Finance & Accounts",
          //   icon: "mdi-alpha-fa-circle",
          //   child: true,
          //   sub_children: true,
          //   grouping_children: true,
          //   screen_code: ["CREATE_MASTERS"],
          //   product_code: ["TECTALIK_FMS"],
          //   screen_codeflag: FMSMaster,
          //   module_codeflag: ["MASTERS"],
          //   children: [
          //     {
          //       id: generateRandomString(),
          //       name: "Tax",
          //       child: true,
          //       icon: "mdi-alpha-t-circle",
          //       to: "/tax",
          //       screen_code: ["CREATE_MASTERS"],
          //       product_code: ["TECTALIK_FMS"],
          //       screen_codeflag: ["SCN_FMS_TAX"],
          //       module_codeflag: ["FMS"],
          //     },
          //     {
          //       id: generateRandomString(),
          //       name: "Tax rate",
          //       child: true,
          //       icon: "mdi-alpha-t-circle",
          //       to: "/tax_rate",
          //       screen_code: ["CREATE_MASTERS"],
          //       product_code: ["TECTALIK_FMS"],
          //       screen_codeflag: ["SCN_FMS_TAX_RATE"],
          //       module_codeflag: ["FMS"],
          //     },
          //   ],
          // },
        ],
      },

      // { name: 'Users',  child: true, icon: 'mdi-account-circle', to: '/users' , screen_code:'CREATE_USERS',},
      //  {
      //   name: "Calendar",
      //   child: true,
      //   icon: "mdi-clipboard-edit",
      //   to: "/Calendar",
      //   screen_code: ["CREATE_MASTERS"],
      //   product_code: ["TECTALIK_CHCK","TECTALIK_TKT"],
      // },
      // {
      //   name: "Appraisal",
      //   child: true,
      //   icon: "mdi-bullhorn ",
      //   to: "/appraisal",
      //   screen_code: ["CREATE_MASTERS"],
      //   product_code: ["TECTALIK_CHCK"],
      // },
      // {
      //   name: "Appraisal Edit",
      //   child: true,
      //   icon: "mdi-bullhorn ",
      //   to: "/appraisal_launch",
      //   screen_code: ["CREATE_MASTERS"],
      //   product_code: ["TECTALIK_CHCK"],
      // },
    ],
  },

  {
    id: generateRandomString(),
    name: "Users",
    child: true,
    icon: "mdi-account-box-multiple-outline",
    screen_code: ["CREATE_USERS"],
    product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
    has_children: true,
    screen_codeflag: ["SCN_USERS","SCN_PASSWORD_PORTAL"],
    module_codeflag: ["USERS"],
    children: [
      {
        id: generateRandomString(),
        name: "Users",
        child: true,
        icon: "mdi-account-cog-outline",
        to: "/users",
        screen_code: ["CREATE_USERS"],
        product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
        screen_codeflag: ["SCN_USERS"],
        module_codeflag: ["USERS"],
      },
      {
        id: generateRandomString(),
        name: "Password Portal",
        child: true,
        icon: "mdi-lock-reset",
        to: "/password_portal",
        screen_code: ["CREATE_USERS"],
        product_code: ["TECTALIK_PMS"],
        screen_codeflag: ["SCN_PASSWORD_PORTAL"],
        module_codeflag: ["USERS"],
      },
    ],
  },
  {
    id: generateRandomString(),
    name: "Setting",
    child: true,
    icon: "mdi-cog-outline",
    screen_code: ["CREATE_MASTERS"],
    product_code: [
      "TECTALIK_CHCK",
      "TECTALIK_TKT",
      "TECTALIK_PMS",
      "TECTALIK_WLK",
    ],
    has_children: true,
    screen_codeflag: [
      "SCN_HOLIDAY",
      "SCN_WEEKOFF",
      "SCN_ROLE",
      "SCN_SCENARIOS",
      "SCN_MAIL_CONFIGURATION",
      "SCN_MAIL_CONFIGURATION",
      "SCN_WLKN_SALESMAN_SETTING",
      "SCN_SCN_WLKN_MANAGER_SETTING",
      "SCN_WLKN_FEEDBACK",
      "SCN_TKT_CMP_SLAB",
      "SCN_PMS_RATING_SETTING",
      "SCN_PMS_PRFMNC_RATING_SETTING",
    ],
    module_codeflag: ["SETTINGS"],
    children: [
      {
        id: generateRandomString(),
        name: "Global",
        child: true,
        icon: "mdi-monitor-dashboard",
        screen_code: ["CREATE_MASTERS"],
        product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
        sub_children: true,
        screen_codeflag: [
          "SCN_HOLIDAY",
          "SCN_WEEKOFF",
          "SCN_ROLE",
          "SCN_SCENARIOS",
          "SCN_MAIL_CONFIGURATION",
          "SCN_TKT_CMP_SLAB",
        ],
        module_codeflag: ["SETTINGS"],
        children: [
          // {
          //   id: generateRandomString(),
          //   name: "Attendance",
          //   child: true,
          //   icon: "mdi-alpha-a-circle",
          //   to: "/attendance_detail",
          //   screen_code: ["CREATE_MASTERS"],
          //   product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
          //   screen_codeflag: ["SCN_HOLIDAY"],
          //   module_codeflag: ["SETTINGS"],
          // },
          // {
          //   id: generateRandomString(),
          //   name: "Lits view",
          //   child: true,
          //   icon: "mdi-alpha-a-circle",
          //   to: "/lits_view",
          //   screen_code: ["CREATE_MASTERS"],
          //   product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
          //   screen_codeflag: ["SCN_HOLIDAY"],
          //   module_codeflag: ["SETTINGS"],
          // },
          // {
          //   id: generateRandomString(),
          //   name: "Shift",
          //   child: true,
          //   icon: "mdi-alpha-s-circle",
          //   to: "/Shift",
          //   screen_code: ["CREATE_MASTERS"],
          //   product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
          //   screen_codeflag: ["SCN_HOLIDAY"],
          //   module_codeflag: ["SETTINGS"],
          // },
          // {
          //   id: generateRandomString(),
          //   name: "Shift Mapping",
          //   child: true,
          //   icon: "mdi-alpha-s-circle",
          //   to: "/shift_mapping",
          //   screen_code: ["CREATE_MASTERS"],
          //   product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
          //   screen_codeflag: ["SCN_HOLIDAY"],
          //   module_codeflag: ["SETTINGS"],
          // },
          // {
          //   id: generateRandomString(),
          //   name: "Break",
          //   child: true,
          //   icon: "mdi-alpha-s-circle",
          //   to: "/break_details",
          //   screen_code: ["CREATE_MASTERS"],
          //   product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
          //   screen_codeflag: ["SCN_HOLIDAY"],
          //   module_codeflag: ["SETTINGS"],
          // },
          // {
          //   id: generateRandomString(),
          //   name: "User/Shift Specific",
          //   child: true,
          //   icon: "mdi-alpha-s-circle",
          //   to: "/user-shift-specific",
          //   screen_code: ["CREATE_MASTERS"],
          //   product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
          //   screen_codeflag: ["SCN_HOLIDAY"],
          //   module_codeflag: ["SETTINGS"],
          // },
          // {
          //   id: generateRandomString(),
          //   name: "Permission",
          //   child: true,
          //   icon: "mdi-alpha-s-circle",
          //   to: "/permission",
          //   screen_code: ["CREATE_MASTERS"],
          //   product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
          //   screen_codeflag: ["SCN_HOLIDAY"],
          //   module_codeflag: ["SETTINGS"],
          // },
          // {
          //   id: generateRandomString(),
          //   name: "Absent Schedule",
          //   child: true,
          //   icon: "mdi-alpha-s-circle",
          //   to: "/absent_schedule",
          //   screen_code: ["CREATE_MASTERS"],
          //   product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
          //   screen_codeflag: ["SCN_HOLIDAY"],
          //   module_codeflag: ["SETTINGS"],
          // },
          // {
          //   id: generateRandomString(),
          //   name: "Documentation",
          //   child: true,
          //   // isnonadmintoshow: true,
          //   icon: "mdi-alpha-b-circle",
          //   to: "/hierarchy_website",
          //   screen_code: ["CREATE_MASTERS"],
          //   product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
          //   screen_codeflag: ["SCN_HOLIDAY"],
          //   module_codeflag: ["SETTINGS"],
          // },
          // {
          //   id: generateRandomString(),
          //   name: "Evants",
          //   child: true,
          //   icon: "mdi-alpha-e-circle",
          //   to: "/event_client",
          //   // iscommontoshow: true
          //   screen_code: ["CREATE_MASTERS"],
          //   product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
          //   screen_codeflag: ["SCN_HOLIDAY"],
          //   module_codeflag: ["SETTINGS"],

          // },
          // {
          //   id: generateRandomString(),
          //   name: "blog",
          //   child: true,
          //   icon: "mdi-alpha-c-circle",
          //   to: "/blog",
          //   // iscommontoshow: true
          //   screen_code: ["CREATE_MASTERS"],
          //   product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
          //   screen_codeflag: ["SCN_HOLIDAY"],
          //   module_codeflag: ["SETTINGS"],

          // },

          // {
          //   id: generateRandomString(),
          //   name: "contacts",
          //   child: true,
          //   icon: "mdi-alpha-c-circle",
          //   to: "/contacts",
          //   // iscommontoshow: true
          //   screen_code: ["CREATE_MASTERS"],
          //   product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
          //   screen_codeflag: ["SCN_HOLIDAY"],
          //   module_codeflag: ["SETTINGS"],
          // },
          {
            id: generateRandomString(),
            name: "Holiday",
            child: true,
            icon: "mdi-alpha-h-circle",
            to: "/holiday",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
            screen_codeflag: ["SCN_HOLIDAY"],
            module_codeflag: ["SETTINGS"],
          },
          {
            id: generateRandomString(),
            name: "Weekoff",
            child: true,
            icon: "mdi-alpha-w-circle",
            to: "/weekoff",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
            screen_codeflag: ["SCN_WEEKOFF"],
            module_codeflag: ["SETTINGS"],
          },
          {
            id: generateRandomString(),
            name: "Role",
            child: true,
            icon: "mdi-alpha-r-circle",
            to: "/role",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
            screen_codeflag: ["SCN_ROLE"],
            module_codeflag: ["SETTINGS"],
          },
          // {
          //   id: generateRandomString(),
          //   name: "Scenarios",
          //   child: true,
          //   icon: "mdi-alpha-s-circle",
          //   to: "/scenarios",
          //   screen_code: ["CREATE_MASTERS"],
          //   product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
          //   screen_codeflag: ["SCN_SCENARIOS"],
          //   module_codeflag: ["SETTINGS"],
          // },
          {
            id: generateRandomString(),
            name: "Mail Configuration",
            child: true,
            icon: "mdi-email-edit ",
            to: "/esclation",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
            underline: false,
            screen_codeflag: ["SCN_MAIL_CONFIGURATION"],
            module_codeflag: ["SETTINGS"],
            // isnonadmintoshow: false,
          },
          {
            id: generateRandomString(),
            name: "Slab",
            child: true,
            icon: "mdi-alpha-s-circle",
            to: "/slab",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
            underline: false,
            screen_codeflag: ["SCN_TKT_CMP_SLAB"],
            module_codeflag: ["SETTINGS"],
          },
          {
            id: generateRandomString(),
            name: "Field Settings",
            child: true,
            icon: "mdi-alpha-p-circle",
            to: "/employee_field_setting",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_CHCK", "TECTALIK_TKT", "TECTALIK_PMS"],
            screen_codeflag: ["SCN_EMP_FIELD_RIGHTS"],
            module_codeflag: ["SETTINGS"],
          },
        ],
      },
      {
        id: generateRandomString(),
        name: "Performance",
        child: true,
        icon: "mdi-monitor-dashboard",
        screen_code: ["CREATE_MASTERS"],
        product_code: ["TECTALIK_PMS"],
        sub_children: true,
        screen_codeflag: [
          "SCN_PMS_RATING_SETTING",
          "SCN_PMS_PRFMNC_RATING_SETTING",
        ],
        module_codeflag: ["SETTINGS"],
        children: [
          {
            id: generateRandomString(),
            name: "Appraisal Score",
            child: true,
            icon: "mdi-alpha-s-circle",
            to: "/rating_setting",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_PMS_RATING_SETTING"],
            module_codeflag: ["SETTINGS"],
          },
          {
            id: generateRandomString(),
            name: "Base Performance",
            child: true,
            icon: "mdi-alpha-p-circle",
            to: "/base_performance",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_PMS_PRFMNC_RATING_SETTING"],
            module_codeflag: ["SETTINGS"],
          },
          {
            id: generateRandomString(),
            name: "Scorecard",
            child: true,
            icon: "mdi-alpha-p-circle",
            to: "/calc_settings",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_PMS"],
            screen_codeflag: ["SCN_SCORECARD_SETTING"],
            module_codeflag: ["SETTINGS"],
          },
        ],
      },
      {
        id: generateRandomString(),
        name: "Walkins",
        child: true,
        icon: "mdi-monitor-dashboard",
        screen_code: ["CREATE_MASTERS"],
        product_code: ["TECTALIK_WLK"],
        sub_children: true,
        screen_codeflag: [
          "SCN_SCN_WLKN_MANAGER_SETTING",
          "SCN_WLKN_SALESMAN_SETTING",
          "SCN_WLKN_FEEDBACK",
        ],
        module_codeflag: ["SETTINGS"],
        children: [
          {
            id: generateRandomString(),
            name: "Feedback",
            child: true,
            icon: "mdi-alpha-f-circle ",
            to: "/walkin/feedback",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_WLK"],
            screen_codeflag: ["SCN_WLKN_FEEDBACK"],
            module_codeflag: ["WALKINS"],
          },
          {
            id: generateRandomString(),
            name: "Salesman Setting",
            child: true,
            icon: "mdi-alpha-s-circle",
            to: "/sales_landing",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_WLK"],
            screen_codeflag: ["SCN_WLKN_SALESMAN_SETTING"],
            module_codeflag: ["SETTINGS"],
          },
          {
            id: generateRandomString(),
            name: "Manager Setting",
            child: true,
            icon: "mdi-alpha-s-circle",
            to: "/designation_setting",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_WLK"],
            screen_codeflag: ["SCN_SCN_WLKN_MANAGER_SETTING"],
            module_codeflag: ["SETTINGS"],
          },
        ],
      },
      {
        id: generateRandomString(),
        name: "FMS",
        child: true,
        icon: "mdi-monitor-dashboard",
        screen_code: ["CREATE_MASTERS"],
        product_code: ["TECTALIK_FMS"],
        sub_children: true,
        screen_codeflag: [
          "SCN_FMS_POLICY_DEF",
          "SCN_FMS_TICKET_DEF",
          "SCN_FMS_TICKETS",
        ],
        module_codeflag: ["SETTINGS"],
        children: [
          {
            id: generateRandomString(),
            name: "Policy Definition",
            child: true,
            icon: "mdi-alpha-f-circle ",
            to: "/policy_definition",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_POLICY_DEF"],
            module_codeflag: ["FMS"],
          },
          {
            id: generateRandomString(),
            name: "Ticket Definition",
            child: true,
            icon: "mdi-alpha-t-circle ",
            to: "/TicketDefinition",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_TICKET_DEF"],
            module_codeflag: ["FMS"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-alpha-a-circle",
            name: "Approval List",
            to: "/approval_list",
            child: true,
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_APPROVAL_LIST"],
            module_codeflag: ["FMS"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-alpha-d-circle",
            name: "Dashboard Roles",
            to: "/fms_dashboard_roles",
            child: true,
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_DASHBOARD_ROLES"],
            module_codeflag: ["FMS"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-alpha-d-circle",
            name: "Dashboard",
            to: "/fms_dashboard",
            child: true,
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_DASHBOARD"],
            module_codeflag: ["FMS"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-alpha-t-circle",
            name: "Terms",
            to: "/terms_and_conditions",
            child: true,
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_TERMS"],
            module_codeflag: ["SETTINGS"],
          },
        ],
      },
    ],
  },

  {
    id: generateRandomString(),
    name: "FMS",
    icon: "mdi-alpha-f-circle",
    has_children: true,
    sub_children: true,
    has_grand_children: false,
    screen_code: ["CREATE_MASTERS"],
    product_code: ["TECTALIK_FMS"],
    screen_codeflag: ["SCN_FMS"],
    module_codeflag: ["FMS"],
    children: [
      {
        id: generateRandomString(),
        icon: "mdi-alpha-c-box",
        name: "Customer",
        to: "/customer_dtl",
        screen_code: ["CREATE_MASTERS"],
        has_children: false,
        product_code: ["TECTALIK_FMS"],
        screen_codeflag: ["SCN_FMS_CUSTOMER"],
        module_codeflag: ["FMS"],
      },
      {
        id: generateRandomString(),
        icon: "mdi-alpha-b-box",
        to: "/building",
        name: "Building",
        screen_code: ["CREATE_MASTERS"],
        has_children: false,
        product_code: ["TECTALIK_FMS"],
        screen_codeflag: ["SCN_FMS_BUILDING"],
        module_codeflag: ["FMS"],
      },
      {
        id: generateRandomString(),
        icon: "mdi-alpha-l-box",
        name: "Lift",
        to: "/lift",
        screen_code: ["CREATE_MASTERS"],
        has_children: false,
        product_code: ["TECTALIK_FMS"],
        screen_codeflag: ["SCN_FMS_LIFT"],
        module_codeflag: ["FMS"],
      },
      {
        id: generateRandomString(),
        icon: "mdi-alpha-c-box",
        name: "Contract",
        to: "/Contract",
        screen_code: ["CREATE_MASTERS"],
        has_children: false,
        product_code: ["TECTALIK_FMS"],
        screen_codeflag: ["SCN_FMS_CONTRACT"],
        module_codeflag: ["FMS"],
      },
      {
        id: generateRandomString(),
        icon: "mdi-alpha-q-box",
        name: "Quotation",
        to: "/Quotation",
        screen_code: ["CREATE_MASTERS"],
        has_children: false,
        product_code: ["TECTALIK_FMS"],
        screen_codeflag: ["SCN_FMS_QUOTATION"],
        module_codeflag: ["FMS"],
      },
      // {
      //   id: generateRandomString(),
      //   icon: "mdi-alpha-t-box",
      //   name: "Tickets",
      //   to: "/fms/tickets",
      //   screen_code: ["CREATE_MASTERS"],
      //   has_children: false,
      //   product_code: ["TECTALIK_FMS"],
      //   screen_codeflag: ["SCN_FMS_TICKETS"],
      //   module_codeflag: ["FMS"],
      // },
      // {
      //   id: generateRandomString(),
      //   name: "Scheduled Tickets",
      //   child: true,
      //   icon: "mdi-alpha-s-box",
      //   to: "/scheduled_tickets",
      //   screen_code: ["CREATE_MASTERS"],
      //   product_code: ["TECTALIK_FMS"],
      //   screen_codeflag: ["SCN_FMS_SCHEDULED_TICKETS"],
      //   module_codeflag: ["FMS"],
      // },
      {
        id: generateRandomString(),
        icon: "mdi-alpha-r-box",
        name: "Receipt",
        to: "/receipt",
        screen_code: ["CREATE_MASTERS"],
        has_children: false,
        product_code: ["TECTALIK_FMS"],
        screen_codeflag: ["SCN_FMS_RECEIPT"],
        module_codeflag: ["FMS"],
      },
      {
        id: generateRandomString(),
        icon: "mdi-alpha-b-box",
        name: "Billing",
        to: "/billing",
        screen_code: ["CREATE_MASTERS"],
        has_children: false,
        product_code: ["TECTALIK_FMS"],
        screen_codeflag: ["SCN_FMS_BILLING"],
        module_codeflag: ["FMS"],
      },
      {
        id: generateRandomString(),
        name: "Tickets",
        icon: "mdi-alpha-t-circle",
        child: true,
        sub_children: true,
        grouping_children: true,
        screen_code: ["CREATE_MASTERS"],
        product_code: ["TECTALIK_FMS"],
        screen_codeflag: ["SCN_FMS_TICKETS","SCN_FMS_SCHEDULED_TICKETS"],
        module_codeflag: ["FMS"],
        children: [
          {
            id: generateRandomString(),
            icon: "mdi-alpha-t-box",
            name: "Tickets",
            to: "/fms/tickets",
            screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_TICKETS"],
            module_codeflag: ["FMS"],
          },
          {
            id: generateRandomString(),
            name: "Scheduled Tickets",
            child: true,
            icon: "mdi-alpha-s-box",
            to: "/scheduled_tickets",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_SCHEDULED_TICKETS"],
            module_codeflag: ["FMS"],
          },
          {
            id: generateRandomString(),
            name: "Scheduled Tickets Count",
            child: true,
            icon: "mdi-alpha-s-box",
            to: "/scheduled_tickets_count",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_SCHEDULED_TICKETS"],
            module_codeflag: ["FMS"],
          },
        ],
      },
      // {
      //   id: generateRandomString(),
      //   icon: "mdi-alpha-m-box",
      //   name: "MR",
      //   to: "/mr_landing",
      //   screen_code: ["CREATE_MASTERS"],
      //   has_children: false,
      //   product_code: ["TECTALIK_FMS"],
      //   screen_codeflag: ["SCN_FMS_MR_LANDING"],
      //   module_codeflag: ["FMS"],
      // },
      {
        id: generateRandomString(),
        name: "MR",
        icon: "mdi-alpha-m-circle",
        child: true,
        sub_children: true,
        grouping_children: true,
        screen_code: ["CREATE_MASTERS"],
        product_code: ["TECTALIK_FMS"],
        screen_codeflag: ["SCN_FMS_MR_LANDING","SCN_FMS_MR_QUOTATION","SCN_FMS_MR_CONTRACT","SCN_FMS_MR_CONTRACT","SCN_FMS_TRANSFER","SCN_FMS_MR_GRN","SCN_FMS_MR_RECEIPT_STATUS"],
        module_codeflag: ["FMS"],
        children: [
          {
            id: generateRandomString(),
            icon: "mdi-alpha-m-box",
            name: "MR",
            to: "/mr_landing",
            screen_code: ["CREATE_MASTERS"],
            child: true,
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_MR_LANDING"],
            module_codeflag: ["FMS"],
          },
          {
            id: generateRandomString(),
            name: "MR Quotation",
            child: true,
            icon: "mdi-alpha-m-box",
            to: "/MrQuotation",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_MR_QUOTATION"],
            module_codeflag: ["FMS"],
          },
          {
            id: generateRandomString(),
            name: "MR Contract",
            child: true,
            icon: "mdi-alpha-m-box",
            to: "/MrContract",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_MR_CONTRACT"],
            module_codeflag: ["FMS"],
          },
          // {
          //   id: generateRandomString(),
          //   name: "MR Indent",
          //   child: true,
          //   icon: "mdi-alpha-m-box",
          //   to: "/mr_indent_landing",
          //   screen_code: ["CREATE_MASTERS"],
          //   product_code: ["TECTALIK_FMS"],
          //   screen_codeflag: ["SCN_FMS_MR_INDENT"],
          //   module_codeflag: ["FMS"],
          // },
          {
            id: generateRandomString(),
            name: "Transfer",
            child: true,
            icon: "mdi-alpha-t-box",
            to: "/transfer_landing",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_TRANSFER"],
            module_codeflag: ["FMS"],
          },
          {
            id: generateRandomString(),
            name: "GRN",
            child: true,
            icon: "mdi-alpha-g-box",
            to: "/grn_landing",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_MR_GRN"],
            module_codeflag: ["FMS"],
          },
          {
            id: generateRandomString(),
            name: "Faulty Material Received Status",
            child: true,
            icon: "mdi-alpha-m-box",
            to: "/mr_receipt_status_landing",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_MR_RECEIPT_STATUS"],
            module_codeflag: ["FMS"],
          },
          // {
          //   id: generateRandomString(),
          //   name: "Stock Report",
          //   child: true,
          //   icon: "mdi-alpha-s-box",
          //   to: "/mr_stock_report",
          //   screen_code: ["CREATE_MASTERS"],
          //   product_code: ["TECTALIK_FMS"],
          //   screen_codeflag: ["SCN_FMS_MR_STOCK_REPORT"],
          //   module_codeflag: ["FMS"],
          // },
          {
            id: generateRandomString(),
            name: "Stock Ledger",
            child: true,
            icon: "mdi-alpha-s-box",
            to: "/mr_stock_ledger",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_MR_STOCK_LEDGER"],
            module_codeflag: ["FMS"],
          },
          {
            id: generateRandomString(),
            name: "MR Receipt",
            child: true,
            icon: "mdi-alpha-s-box",
            to: "/mr_receipt_landing",
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_MR_RECEIPT_STATUS"],
            module_codeflag: ["FMS"],
          },
        ],
      },
      {
        id: generateRandomString(),
        name: "TIS",
        icon: "mdi-alpha-t-circle",
        child: true,
        sub_children: true,
        has_grand_children: true,
        screen_code: ["CREATE_MASTERS"],
        product_code: ["TECTALIK_FMS"],
        screen_codeflag: ["SCN_FMS_TIS"],
        module_codeflag: ["FMS"],
        children: [
          {
            id: generateRandomString(),
            name: "TIS",
            icon: "mdi-alpha-t-circle",
            child: true,
            sub_children: true,
            grouping_children: true,
            screen_code: ["CREATE_MASTERS"],
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_TIS"],
            module_codeflag: ["FMS"],
            children: [
              {
                id: generateRandomString(),
                icon: "mdi-alpha-t-box",
                name: "TIS",
                to: "/tis_landing",
                screen_code: ["CREATE_MASTERS"],
                child: true,
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_TIS"],
                module_codeflag: ["FMS"],
              },
            ],
          },
          {
            id: generateRandomString(),
            name: "TIS Survey",
            icon: "mdi-alpha-t-circle",
            child: true,
            sub_children: true,
            grouping_children: true,
            screen_code: ["CREATE_MASTERS"],  
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_TIS_SURVEY"],
            module_codeflag: ["FMS"],
            children: [
              {
                id: generateRandomString(),
                icon: "mdi-alpha-t-box",
                name: "TIS Survey Group",
                to: "/tis_survey_group",
                screen_code: ["CREATE_MASTERS"],
                child: true,
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_TIS_SURVEY_GROUP"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                icon: "mdi-alpha-t-box",
                name: "TIS Survey Field",
                to: "/tis_survey_field",
                screen_code: ["CREATE_MASTERS"],
                child: true,
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_TIS_SURVEY_FIELD"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                icon: "mdi-alpha-t-box",
                name: "TIS Survey Type",
                to: "/tis_survey_type",
                screen_code: ["CREATE_MASTERS"],
                child: true,
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_TIS_SURVEY_TYPE"],
                module_codeflag: ["FMS"],
              },
              {
                id: generateRandomString(),
                icon: "mdi-alpha-t-box",
                name: "TIS Survey Value",
                to: "/tis_survey_value",
                screen_code: ["CREATE_MASTERS"],
                child: true,
                product_code: ["TECTALIK_FMS"],
                screen_codeflag: ["SCN_FMS_TIS_SURVEY_VALUE"],
                module_codeflag: ["FMS"],
              },
            ],
          },
        ],
      },
      // {
      //   id: generateRandomString(),
      //   icon: "mdi-alpha-d-box",
      //   name: "Deposits",
      //   to: "/deposits",
      //   screen_code: ["CREATE_MASTERS"],
      //   has_children: false,
      //   product_code: ["TECTALIK_FMS"],
      //   screen_codeflag: ["SCN_FMS_DEPOSITS"],
      //   module_codeflag: ["FMS"],
      // },
      // {
      //     id: generateRandomString(),
      //     icon: "mdi-alpha-t-box",
      //     name: "Terms",
      //     to: "/terms_and_conditions",
      //     screen_code: ["CREATE_MASTERS"],
      //     has_children: false,
      //     product_code: ["TECTALIK_FMS"],
      //     screen_codeflag: ["SCN_FMS_TICKETS"],
      //     module_codeflag: ["FMS"],
      // },
      // {
      //     id: generateRandomString(),
      //     icon: "mdi-alpha-a-box",
      //     name: "Approval",
      //     screen_code: ["CREATE_MASTERS"],
      //     sub_children: true,
      //     product_code: ["TECTALIK_FMS"],
      //     screen_codeflag: ["SCN_FMS_TICKETS"],
      //     module_codeflag: ["FMS"],
      //     children:[{
      //         id: generateRandomString(),
      //         icon: "mdi-alpha-a-box",
      //         name: " Approval List",
      //         to: "/approval_list",
      //         screen_code: ["CREATE_MASTERS"],
      //         has_children: false,
      //         product_code: ["TECTALIK_FMS"],
      //         screen_codeflag: ["SCN_FMS_TICKETS"],
      //         module_codeflag: ["FMS"],
      //     }],
      // },

      {
        id: generateRandomString(),
        icon: "mdi-alpha-j-box",
        name: "Job History",
        screen_code: ["CREATE_MASTERS"],
        sub_children: true,
        product_code: ["TECTALIK_FMS"],
        screen_codeflag: [
          "SCN_FMS_ATT_LOOKUP_GROUP",
          "SCN_FMS_ATT_LOOKUP_TYPE",
          "SCN_FMS_ATT_LOOKUP",
        ],
        module_codeflag: ["FMS"],
        children: [
          {
            id: generateRandomString(),
            icon: "mdi-alpha-a-box",
            name: "Attribute Lookup Group",
            to: "/attribute_lookup_group",
            screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_ATT_LOOKUP_GROUP"],
            module_codeflag: ["FMS"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-alpha-a-box",
            name: "Attribute Lookup Type",
            to: "/attribute_lookup_type",
            screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_ATT_LOOKUP_TYPE"],
            module_codeflag: ["FMS"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-alpha-a-box",
            name: "Attribute Lookup",
            to: "/attribute_lookup",
            screen_code: ["CREATE_MASTERS"],
            sub_children: true,
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_ATT_LOOKUP"],
            module_codeflag: ["FMS"],
          },
        ],
      },
      {
        id: generateRandomString(),
        icon: "mdi-alpha-r-box",
        name: "Reports",
        screen_code: ["CREATE_MASTERS"],
        sub_children: true,
        product_code: ["TECTALIK_FMS"],
        screen_codeflag: [
          "SCN_FMS_TECHNICAL_CHART",
          "SCN_FMS_OUTSTANDING_REPORT",
          "SCN_FMS_LIFT_REPORTS",
          "SCN_FMS_BUILDING_TICKET_REPORTS",
          "SCN_FMS_ATTENDANCE_REPORTS",
          "SCN_FMS_DAILY_ATTENDANCE_REPORTS",
          "SCN_FMS_PREVENTIVE_MAINTENANCE_REPORTS",
          "SCN_FMS_RECEIPT_BOOKING_STATUS_REPORTS",
          "SCN_FMS_AMC_NOT_RENEWED_REPORTS",
          "SCN_FMS_CURRENT_MONTH_AMC_RENEWAL_REPORTS",
          "SCN_FMS_ADVANCE_AMC_RENEWAL_REPORTS",
          "SCN_FMS_CANCELLED_CONTRACT_REPORTS"
        ],
        module_codeflag: ["FMS"],
        children: [
          {
            id: generateRandomString(),
            icon: "mdi-alpha-t-box",
            name: "Technical Chart",
            to: "/reports/technical_chart",
            screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_TECHNICAL_CHART"],
            module_codeflag: ["FMS"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-alpha-o-box",
            name: "Outstanding",
            to: "/reports/outstanding",
            screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_OUTSTANDING_REPORT"],
            module_codeflag: ["FMS"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-alpha-l-box",
            name: "lift reports",
            to: "/reports/lift_reports",
            screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_LIFT_REPORTS"],
            module_codeflag: ["FMS"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-alpha-b-box",
            name: "Building Ticket Reports",
            to: "/reports/building_ticket_reports",
            screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_BUILDING_TICKET_REPORTS"],
            module_codeflag: ["FMS"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-alpha-a-box",
            name: "Attendance Reports",
            to: "/reports/attendance_reports",
            screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_ATTENDANCE_REPORTS"],
            module_codeflag: ["FMS"],
          }, 
          {
            id: generateRandomString(),
            icon: "mdi-alpha-d-box",
            name: "Daily Attendance Reports",
            to: "/reports/daily_attendance_reports",
            screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_DAILY_ATTENDANCE_REPORTS"],
            module_codeflag: ["FMS"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-alpha-p-box",
            name: "Preventive Maintenance Reports",
            to: "/reports/preventive_maintenance_reports",
            screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_PREVENTIVE_MAINTENANCE_REPORTS"],
            module_codeflag: ["FMS"],
          },

          {
            id: generateRandomString(),
            icon: "mdi-alpha-r-box",
            name: "Receipt Booking Status Reports",
            to: "/reports/receipt_booking_status_reports",
            screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_RECEIPT_BOOKING_STATUS_REPORTS"],
            module_codeflag: ["FMS"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-alpha-n-box",
            name: "Not Renewed List",
            to: "/reports/amc_not_renewed_reports",
            screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_AMC_NOT_RENEWED_REPORTS"],
            module_codeflag: ["FMS"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-alpha-c-box",
            name: "Current Month Renewal List",
            to: "/reports/current_month_amc_renewal_reports",
            screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_CURRENT_MONTH_AMC_RENEWAL_REPORTS"],
            module_codeflag: ["FMS"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-alpha-a-box",
            name: "Advance Renewal List",
            to: "/reports/advance_amc_renewal_reports",
            screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_ADVANCE_AMC_RENEWAL_REPORTS"],
            module_codeflag: ["FMS"],
          },
          {
            id: generateRandomString(),
            icon: "mdi-alpha-c-box",
            name: "Cancelled Contract Report",
            to: "/reports/cancelled_contract_reports",
            screen_code: ["CREATE_MASTERS"],
            has_children: false,
            product_code: ["TECTALIK_FMS"],
            screen_codeflag: ["SCN_FMS_CANCELLED_CONTRACT_REPORTS"],
            module_codeflag: ["FMS"],
          },

        ],
      },
    ],
  },

  {
    id: generateRandomString(),
    name: "Dashboard",
    icon: "mdi-alpha-d-circle",
    has_children: true,
    screen_code: ["CREATE_MASTERS"],
    product_code: ["TECTALIK_PMS"],
    screen_codeflag: ["SCN_DASHBOARD"],
    module_codeflag: ["DASHBOARD"],
    children: [
      {
        id: generateRandomString(),
        icon: "mdi-alpha-d-box",
        to: "/dashboard_landing",
        name: "ORO",
        screen_code: ["CREATE_MASTERS"],
        has_children: false,
        product_code: ["TECTALIK_PMS"],
        screen_codeflag: ["SCN_DASHBOARD"],
        module_codeflag: ["DASHBOARD"],
      },
    ],
  },
  {
    id: generateRandomString(),
    name: "Customer Target",
    has_children: false,
    icon: "mdi-alpha-c-circle",
    to: "/customer/target",
    product_code: ["TECTALIK_PMS"],
    screen_codeflag: ["SCN_CUSTOMER_TARGET"],
    module_codeflag: ["PERFORMANCE"],
  },
  {
    id: generateRandomString(),
    name: "Customer Report",
    has_children: false,
    icon: "mdi-alpha-c-circle",
    to: "/customer/target/report",
    product_code: ["TECTALIK_PMS"],
    screen_codeflag: ["SCN_CUSTOMER_TARGET_REPORT"],
    module_codeflag: ["PERFORMANCE"],
  },
];

export default menu_list;
