<style>
/*@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');*/
/*@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");*/

@font-face {
  font-family: Axiforma-Medium;
  src: url("../assets/font/Axiforma-Medium.ttf");
}
/* @supports(margin: max(0px)) {
    margin-top: max(env(safe-area-inset-top), 20px);
} */

body {
  /*font-family: 'Roboto', sans-serif;*/
  /*font-family: "Open Sans", sans-serif;*/
  font-family: Axiforma-Medium !important;
}

* {
  font-family: Axiforma-Medium !important;
}
.v-treeview-node__children {
  font-size: 14px;
  font-weight: 100;
}
/*.v-btn__content {
  text-transform: capitalize !important;
  font-weight: 700;
  font-size: 1.4em;
}*/
/*.v-data-table
{
  font-size: 28px !important;
}*/
.v-treeview-node__root {
  display: inline-flex !important;
  align-items: center;
  min-height: 48px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  position: relative;
  width: 100%;
}
.v-icon.v-icon.v-icon--link {
  cursor: pointer;
  outline: none;
  /*margin-left: 25px;*/
}
.link-design {
  font-weight: 450;
  color: #333 !important;
  text-decoration: none;
  /* background-color: #BBDEFB;  */
}
.link-head-design {
  /* font-weight: 600;  */
  text-decoration: none;
  color: #333 !important;
}
#inspire {
  background: #f2f2f2 !important;
}
.side-bar-cus {
  background: #f2f2f2 !important;
}

.v-list-item__content {
  padding: 8px 0 !important;
}
.v-text-field input {
  font-size: 1.2em;
  font-size: inherit;
}
.v-text-field--filled {
  background-color: white !important;
}
/* .v-text-field--rounded {
  
    border-radius: 2px !important;
} */
.custom_bgColor {
  background-color: white;
}
label {
  font-size: 18px !important;
  font-weight: bold;
  color: black;
  line-height: 3.1;
  font-size: 17px;
  font-weight: 400;
}
.v-menu__content {
  min-width: 141px !important;
}
/* header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 44px;

       
    padding-top: constant(safe-area-inset-top); */

/* } */

#style-1 .v-navigation-drawer__content::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
#style-1 .v-navigation-drawer__content::-webkit-scrollbar-thumb {
  background-color: #4e4d4c;
  background-image: -webkit-linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}
#style-1 .v-navigation-drawer__content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.pi {
  font-family: "primeicons" !important;
}
</style>
<style scoped>
/*.v-navigation-drawer__content > .v-list-item {
  min-height: 64px !important;
}

.v-navigation-drawer--mini-variant.v-navigation-drawer--open {
  width: 75px !important;
}*/
html {
  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}
</style>

<template>
  <v-app id="inspire">
    <v-overlay :value="overlay_load" z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-navigation-drawer
      app
      permanent
      :key="drawer"
      :expand-on-hover="!drawer"
      fixed
      clipped
      class="drawer-style overflow-hidden"
      id="style-1"
      style="background: #050c44 !important; color: #fff"
      v-if="iflag == 0 && logged_user_Email != null && show_hide_mob_btn == 1"
      v-show="
        this.$route.name == 'survey_answer_customer_result' ? false : true
      "
    >
      <!-- <v-list-item style="background: white">
        <v-list-item-content>
          <v-list-item-title class="title" style="text-align: ">
            <img
              src="../assets/tectalik_small_transparent1.png"
              width="60px"
              class="pr-9"
              v-if="!drawer"
            />
            <img
              src="../assets/tectalik-logos.png"
              width="150px"
              class="pr-6"
              v-if="drawer"
            />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <v-divider></v-divider>
      <v-list dense dark style="background: #050c44 !important">
        <span v-for="item in items" :key="item.id">
          <v-list-group
            v-if="item.has_children && checkproductvalue(item)"
            :prepend-icon="item.icon"
            no-action
            color="orange"
            @click="
              (item.name == 'Team', 'Score') ? router_pusher(item.to) : ''
            "
          >
            <!-- masters here -->
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.name"
                  class="xyz x-1"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
            <span
              v-for="child in item.children"
              :key="child.key"
              v-if="checkproductvalue(child)"
            >
              <v-list-item
                :to="child.to"
                :title="child.name"
                v-if="!child.sub_children"
                class="pl-10"
              >
                <v-list-item-icon>
                  <v-icon v-text="child.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="child.name"
                    class="xyz x-2"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-group
                v-if="child.sub_children || child.has_children"
                no-action
                sub-group
                color="orange"
              >
                <!-- under masters -->
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="child.name"
                      class="xyz x-3"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="subchild in child.children"
                  :key="subchild.id"
                  :to="subchild.to"
                  :title="subchild.name"
                  v-if="
                    !child.has_grand_children && checkproductvalue(subchild)
                  "
                  class="pl-10"
                >
                  <v-list-item-icon>
                    <v-icon v-text="subchild.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="subchild.name"
                      class="xyz x-4"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- fms masters -->
                <v-list-item
                  v-for="subchild in child.children"
                  :key="subchild.id"
                  :to="subchild.to"
                  :title="subchild.name"
                  v-if="child.has_grand_children && checkproductvalue(subchild)"
                  class="pl-5"
                >
                  <v-list-group no-action sub-group color="orange">
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="subchild.name"
                          class="xyz krishna"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-list-item-content>
                      <v-list-item
                        v-for="grouping_children in subchild.children"
                        :key="grouping_children.id"
                        :to="grouping_children.to"
                        :title="grouping_children.name"
                        v-if="checkproductvalue(grouping_children)"
                        class="pl-5"
                      >
                        <v-list-item-icon>
                          <v-icon v-text="grouping_children.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="grouping_children.name"
                            class="xyz vbm"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-content>
                  </v-list-group>
                </v-list-item>
              </v-list-group>
            </span>

            <!-- sub_children -->
          </v-list-group>

          <v-list-item
            :to="item.to"
            :title="item.name"
            v-if="
              !item.has_children &&
              checkproductvalue(item) &&
              !item.onlytextviews
            "
            color="orange"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="item.name"
                class="xyz x-5"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-subheader
            inset
            v-if="item.onlytextviews && checkproductvalue(item)"
            style="font-size: 14px; font-weight: bold"
            >{{ user_name }}'s Space</v-subheader
          >
          <!-- <span ></span> -->

          <v-divider
            v-if="item.underline && checkproductvalue(item)"
            color="#fff"
          ></v-divider>
        </span>
      </v-list>
    </v-navigation-drawer>
    <!--desktop side menu ends  -->
    <!--mobile side menu starts  -->
    <v-navigation-drawer
      app
      permanent
      :key="drawer"
      :expand-on-hover="!drawer"
      fixed
      clipped
      class="drawer-style"
      id="style-1"
      style="background: #050c44 !important; color: #fff"
      v-if="iflag == 1 && show_hide_mob_btn == 2"
    >
      <!-- <v-list-item style="background: white">
        <v-list-item-content>
          <v-list-item-title class="title" style="text-align: ">
            <img
              src="../assets/tectalik_small_transparent1.png"
              width="60px"
              class="pr-9"
              v-if="!drawer"
            />
            <img
              src="../assets/tectalik-logos.png"
              width="150px"
              class="pr-6"
              v-if="drawer"
            />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <v-divider></v-divider>
      <v-list dense dark style="background: #050c44 !important">
        <span v-for="item in items" :key="item.id">
          <v-list-group
            v-if="item.has_children && checkproductvalue(item)"
            :prepend-icon="item.icon"
            no-action
            color="orange"
            @click="
              (item.name == 'Team', 'Score') ? router_pusher(item.to) : ''
            "
          >
            <!-- :to="(item.name == 'Act') ? router_pusher(item.to) : ''" -->
            <!-- :to="(item.name == 'Team') ? '/show_list' : '/show_list'" -->
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.name"
                  class="xyz x-6"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
            <span
              v-for="child in item.children"
              :key="child.key"
              v-if="checkproductvalue(child)"
            >
              <v-list-item
                :to="child.to"
                :title="child.name"
                v-if="!child.sub_children"
                class="pl-10"
              >
                <v-list-item-icon>
                  <v-icon v-text="child.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="child.name"
                    class="xyz x-7"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-group
                v-if="child.sub_children"
                no-action
                sub-group
                color="orange"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="child.name"
                      class="xyz x-8"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item
                  v-for="subchild in child.children"
                  :key="subchild.id"
                  :to="subchild.to"
                  :title="subchild.name"
                  v-if="checkproductvalue(subchild)"
                  class="pl-10"
                >
                  <v-list-item-icon>
                    <v-icon v-text="subchild.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="subchild.name"
                      class="xyz x-9"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </span>
            <!-- sub_children -->
          </v-list-group>
          <v-list-item
            :to="item.to"
            :title="item.name"
            v-if="
              !item.has_children &&
              checkproductvalue(item) &&
              !item.onlytextviews
            "
            color="orange"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="item.name"
                class="xyz x-10"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-subheader
            inset
            v-if="item.onlytextviews && checkproductvalue(item)"
            style="font-size: 14px; font-weight: bold"
            >{{ user_name }}'s Space</v-subheader
          >
          <!-- <span ></span> -->
          <v-divider
            v-if="item.underline && checkproductvalue(item)"
            color="#fff"
          ></v-divider>
        </span>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="drawer"
      app
      class="side-bar-cus"
      prominent
      style="
        background: #050c44 !important;
        color: #fff;
        top: 57px;
        z-index: 50;
      "
      v-if="show_hide_mob_btn == 2"
    >
      <v-list-item style="background: #050c44">
        <v-list-item-content>
          <!-- MS-LOGO-1-blue.png, tectalik logo.png" -->
          <v-list-item-title class="title" style="text-align: center">
            <img src="../assets/MS-LOGO-1-blue.png" width="130px" />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense dark style="background: #050c44">
        <span v-for="item in items" :key="item.id">
          <v-list-group
            v-if="item.has_children && checkproductvalue(item)"
            :prepend-icon="item.icon"
            no-action
            color="orange"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.name"
                  class="xyz x-11"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
            <span
              v-for="child in item.children"
              :key="child.id"
              v-if="checkproductvalue(child)"
            >
              <v-list-item
                :to="child.to"
                :title="child.name"
                v-if="!child.sub_children"
                class="pl-10"
              >
                <v-list-item-icon>
                  <v-icon v-text="child.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="child.name"
                    class="xyz x-12"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-group
                v-if="child.sub_children"
                no-action
                sub-group
                color="orange"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="child.name"
                      class="xyz x-13"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="subchild in child.children"
                  :key="subchild.id"
                  :to="subchild.to"
                  :title="subchild.name"
                  v-if="
                    !child.has_grand_children && checkproductvalue(subchild)
                  "
                  class="pl-10"
                >
                  <v-list-item-icon>
                    <v-icon v-text="subchild.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="subchild.name"
                      class="xyz x-14"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- fms masters -->
                <v-list-item
                  v-for="subchild in child.children"
                  :key="subchild.id"
                  :to="subchild.to"
                  :title="subchild.name"
                  v-if="child.has_grand_children && checkproductvalue(subchild)"
                  class="pl-10"
                >
                  <v-list-group no-action sub-group color="orange">
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="subchild.name"
                          class="xyz krishna"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-list-item-content>
                      <v-list-item
                        v-for="grouping_children in subchild.children"
                        :key="grouping_children.id"
                        :to="grouping_children.to"
                        :title="grouping_children.name"
                        v-if="checkproductvalue(grouping_children)"
                        class="pl-10"
                      >
                        <v-list-item-icon>
                          <v-icon v-text="grouping_children.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="grouping_children.name"
                            class="xyz vbm"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-content>
                  </v-list-group>
                </v-list-item>
              </v-list-group>
            </span>
          </v-list-group>

          <!--  -->
          <v-list-item
            :to="item.to"
            :title="item.name"
            v-if="
              !item.has_children &&
              checkproductvalue(item) &&
              !item.onlytextviews
            "
            color="orange"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="item.name"
                class="xyz x-15"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-subheader
            inset
            v-if="item.onlytextviews && checkproductvalue(item)"
            style="font-size: 14px; font-weight: bold"
            >{{ user_name }}'s Space</v-subheader
          >
          <v-divider
            v-if="item.underline && checkproductvalue(item)"
            color="#fff"
          ></v-divider>
        </span>
      </v-list>
    </v-navigation-drawer>
    <!-- desktop_view -->
    <div v-if="iflag == 1">
      <v-app-bar
        fixed
        color="white"
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <div class="align-center d-flex" v-if="iflag == 0">
          <img
            src="../assets/tectalik_small_transparent1.png"
            width="35px"
            height="30px"
            class="pa-0"
            v-if="!drawer"
          />
          <img
            src="../assets/tectalik logo.png"
            width="130px"
            height="40px"
            class="pa-0"
            v-if="drawer"
          />
        </div>
        <div style="flex-grow: 0.03 !important" v-if="iflag == 0"></div>
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <b>{{ entity_name }}</b>
        <v-spacer></v-spacer>
        <div v-if="domain != 'admin'" class="float-right">
          <v-icon
            class="mr-3"
            size="25"
            v-if="
              JSON.parse(entityData) ? JSON.parse(entityData).length > 1 : false
            "
            color="black"
            @click="switch_entity"
            title="Switch Company"
            >mdi-swap-horizontal</v-icon
          >
          <v-spacer></v-spacer>
        </div>
        <b>{{ user_name }}</b
        >&nbsp;&nbsp;
        <v-icon class="ml-3" size="25" color="black" @click="logout()"
          >mdi-power-settings</v-icon
        >
      </v-app-bar>
    </div>
    <div v-if="iflag == 0">
      <v-app-bar
        app
        clipped-left
        class="elevation-2"
        v-if="logged_user_Email != null"
      >
        <div class="align-center d-flex" v-if="iflag == 0">
          <img
            src="../assets/tectalik_small_transparent1.png"
            width="35px"
            height="30px"
            class="pa-0"
            v-if="!drawer"
          />
          <img
            src="../assets/tectalik logo.png"
            width="130px"
            height="40px"
            class="pa-0"
            v-if="drawer"
          />
        </div>
        <div style="flex-grow: 0.03 !important" v-if="iflag == 0"></div>
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

        <!-- <v-toolbar-title>Tectalik</v-toolbar-title> -->
        <b>{{ entity_name }}</b>

        <v-spacer></v-spacer>
        <div class="text-center">
          <v-btn
            rounded
            color="red"
            class="mr-3"
            @click="refresh_token()"
            v-if="badge_flag == true"
          >
            <v-icon size="25">mdi-refresh</v-icon>
            <!-- <template v-slot:activator="{ on, attrs }">
            <v-badge
              bordered
              color="red"
              overlap
              v-if="badge_flag == true"
            >
              <span>
                <v-icon v-bind="attrs" v-on="on"
                  >mdi-refresh</v-icon
                >
              </span>
            </v-badge>
          </template> -->
          </v-btn>
          <v-dialog persistent v-model="dialog" width="700">
            <template v-slot:activator="{ on, attrs }">
              <v-btn style="margin-right: 6px" rounded v-bind="attrs" v-on="on">
                <v-icon size="25">mdi-plus</v-icon>
                <h4 style="padding-top: 3px">New</h4>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5 grey lighten-2"
                >Quick Add</v-card-title
              >
              <!-- <v-row no-gutters>
               
                <v-col cols="12" md="3">
                 
                    <v-col cols="12" md="4" style="padding-top: 0px">
                    <v-btn
                      v-if="screen_auth('SCN_EMPLOYEE', 'add_screen')"
                      class="ma-1"
                      color="black"
                      plain
                      @click="add_employee()"
                    >
                      Add Employee
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="4" style="padding-top: 0px">
                    <v-btn
                      v-if="screen_auth('SCN_SERVICE', 'add_screen')"
                      class="ma-1"
                      color="black"
                      plain
                      @click="addService()"
                    >
                      Add Service
                    </v-btn>
                  </v-col>
                
                   <v-col cols="12" md="4" style="padding-top: 0px">
                    <v-btn
                      v-if="screen_auth('SCN_CHCK_MY_CHECKLIST', 'add_screen')"
                      class="ma-1"
                      @click="mainchecklist()"
                      color="black"
                      plain
                    >
                      ADD Checklist
                    </v-btn>
                  </v-col>
                </v-col>
            
                <v-col cols="12" md="3">
                  <v-col cols="12" md="4" style="padding-top: 0px">
                    <v-btn
                      v-if="screen_auth('SCN_PMS_KPI_ADDITION', 'add_screen')"
                      class="ma-1"
                      color="black"
                      @click="addkpi('add')"
                      plain
                    >
                      ADD Kpi
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="4" style="padding-top: 0px">
                    <v-btn 
                    v-if="screen_auth('SCN_VISITS','add_screen')"
                    class="ma-1"
                     color="black"
                      @click="logvisit()"
                       plain>
                      Log Visit
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="4" style="padding-top: 0px">
                    <v-btn
                    v-if="screen_auth('SCN_VISITS','add_screen')"
                      class="ma-1"
                      color="black"
                      @click="planvisit()"
                      plain
                    >
                      Plan Visit
                    </v-btn>
                  </v-col>
                 
                </v-col>
              
                <v-col cols="12" md="3">
                   <v-col cols="12" md="4" style="padding-top: 0px">
                    <v-btn
                    v-if="screen_auth('SCN_TKT_TICKETS','add_screen')"
                      class="ma-1"
                      color="black"
                      plain
                      @click="close_ticket()"
                    >
                      ADD Tickets
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="4" style="padding-top: 0px">
                    <v-btn
                    v-if="screen_auth('SCN_WALKINS','add_screen')"
                      class="ma-1"
                      color="black"
                      @click="addwalkins()"
                      plain
                    >
                      ADD Walkins
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="4" style="padding-top: 0px">
                    <v-btn 
                    v-if="screen_auth('SCN_PLANNING','add_screen')"
                    class="ma-1"
                     color="black"
                      @click="addgoals()"
                       plain>
                      ADD Goals
                    </v-btn>
                  </v-col>
                </v-col>
                
                   <v-col cols="12" md="3">
                    <v-col cols="12" md="4" style="padding-top: 0px">
                    <v-btn
                      v-if="screen_auth('SCN_SURVEY', 'add_screen')"
                      class="ma-1"
                      color="black"
                      @click="addsurvey()"
                      plain
                    >
                     
                      ADD Survey
                    </v-btn>
                  </v-col>
                  
                  </v-col>
                </v-row> -->
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="4"
                  style="padding-top: 0px"
                  v-if="screen_auth('SCN_EMPLOYEE', 'add_screen')"
                >
                  <v-btn
                    class="ma-1"
                    color="black"
                    plain
                    @click="add_employee()"
                  >
                    Add Employee
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  style="padding-top: 0px"
                  v-if="screen_auth('SCN_SERVICE', 'add_screen')"
                >
                  <v-btn class="ma-1" color="black" plain @click="addService()">
                    Add Service
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  style="padding-top: 0px"
                  v-if="screen_auth('SCN_CHCK_MY_CHECKLIST', 'add_screen')"
                >
                  <v-btn
                    class="ma-1"
                    @click="mainchecklist()"
                    color="black"
                    plain
                  >
                    ADD Checklist
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  style="padding-top: 0px"
                  v-if="screen_auth('SCN_PMS_KPI_ADDITION', 'add_screen')"
                >
                  <v-btn
                    class="ma-1"
                    color="black"
                    @click="addkpi('add')"
                    plain
                  >
                    ADD Kpi
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  style="padding-top: 0px"
                  v-if="screen_auth('SCN_VISITS', 'add_screen')"
                >
                  <v-btn class="ma-1" color="black" @click="logvisit()" plain>
                    Plan Visit
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  style="padding-top: 0px"
                  v-if="screen_auth('SCN_VISITS', 'add_screen')"
                >
                  <v-btn class="ma-1" color="black" @click="planvisit()" plain>
                    Log Visit
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  style="padding-top: 0px"
                  v-if="screen_auth('SCN_TKT_TICKETS', 'add_screen')"
                >
                  <v-btn
                    class="ma-1"
                    color="black"
                    plain
                    @click="close_ticket()"
                  >
                    ADD Ticket
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  style="padding-top: 0px"
                  v-if="screen_auth('SCN_WALKINS', 'add_screen')"
                >
                  <v-btn
                    class="ma-1"
                    color="black"
                    @click="addwalkins()"
                    :disabled="branch_ID == 'null' || branch_ID == '0'"
                    plain
                  >
                    ADD Walkin
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  style="padding-top: 0px"
                  v-if="screen_auth('SCN_PLANNING', 'add_screen')"
                >
                  <v-btn class="ma-1" color="black" @click="addgoals()" plain>
                    ADD Goal
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  style="padding-top: 0px"
                  v-if="screen_auth('SCN_SURVEY', 'add_screen')"
                >
                  <v-btn class="ma-1" color="black" @click="addsurvey()" plain>
                    ADD Survey
                  </v-btn>
                </v-col>

                <v-col v-if="this.branch_ID == 'null' || this.branch_ID == '0'">
                  <div class="mx-5">
                    <v-alert dense outlined type="error">
                      To add a walkin - please
                      <strong>map a branch</strong> to the user & re-login
                    </v-alert>
                  </div>
                </v-col>
              </v-row>

              <v-divider></v-divider>
              <!-- <v-card-title class="text-h5 grey lighten-2">
                    My Zone
                  </v-card-title> -->
              <v-card-title style="margin-top: 2%" class="text-h5 lighten-2">
                My Zone
              </v-card-title>
              <!-- Reports -->
              <!-- <v-row no-gutters>
                      <v-col cols="12" md="3">
                  <v-col cols="12" md="4" style="padding-top: 0px">
                    <v-btn
                       v-if="screen_auth('SCN_CHCK_MY_CHECKLIST', 'add_screen')"
                      class="ma-1"
                      color="black"
                      plain
                      @click="open_checklist()"
                    >
                     My Checklist
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="4" style="padding-top: 0px">
                    <v-btn
                        v-if="screen_auth('SCN_TKT_TICKETS', 'add_screen')"
                      class="ma-1"
                      color="black"
                      plain
                     @click="open_ticket()"
                    >
                      My Tickets
                    </v-btn>
                  </v-col>
                   <v-col cols="12" md="4" style="padding-top: 0px">
                    <v-btn
                      v-if="screen_auth('SCN_SURVEY', 'add_screen')"
                      class="ma-1"
                      color="black"
                      plain
                      @click="open_survey()"
                    >
                      My Survey
                    </v-btn>
                  </v-col>
                </v-col>
                 <v-col cols="12" md="3">
                  <v-col cols="12" md="4" style="padding-top: 0px">
                    <v-btn
                       v-if="screen_auth('SCN_WALKINS', 'add_screen')"
                      class="ma-1"
                      color="black"
                      plain
                      @click="open_walkins()"
                    >
                     My Walkins
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="4" style="padding-top: 0px">
                    <v-btn
                      v-if="screen_auth('SCN_VISITS', 'add_screen')"
                      class="ma-1"
                      color="black"
                      plain
                     @click="open_visit()"
                    >
                      My Visit
                    </v-btn>
                  </v-col>
                 
                </v-col>
                <v-col  cols="12" md="3">
                  <v-col cols="12" md="4" style="padding-top: 0px">
                    <v-btn
                      v-if="screen_auth('SCN_CHCK_REPORTS', 'add_screen')"
                      class="ma-1"
                      color="black"
                      plain
                      @click="checklist_report()"
                    >
                     Checklist Report
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="4" style="padding-top: 0px">
                    <v-btn
                       v-if="screen_auth('SCN_TKT_REPORTS', 'add_screen')"
                      class="ma-1"
                      color="black"
                      plain
                      @click="ticket_report()"
                    >
                      Tickets Report
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="4" style="padding-top: 0px">
                    <v-btn
                      v-if="screen_auth('SCN_WLK_RPRT', 'add_screen')"
                      class="ma-1"
                      color="black"
                      plain
                      @click="walkins_report()"
                    >
                      Walkins Report
                    </v-btn>
                  </v-col>
                </v-col>
                <v-col cols="12" md="3">
                  
                  <v-col cols="12" md="4" style="padding-top: 0px">
                    <v-btn
                    v-if="screen_auth('SCN_VISIT_REPORTS', 'add_screen')"
                      class="ma-1"
                      color="black"
                      plain
                       @click="visit_report()"
                    >
                      Visit Report
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="4" style="padding-top: 0px">
                    <v-btn
                     v-if="screen_auth('SCN_SURVEY_REPORTS', 'add_screen')"
                      class="ma-1"
                      color="black"
                      plain
                      @click="survey_report()"
                    >
                      Survey Report
                    </v-btn>
                  </v-col>
                     <v-col cols="12" md="4" style="padding-top: 0px">
                    <v-btn 
                    v-if="screen_auth('SCN_INCENTIVE_POLICY', 'add_screen')"
                      class="ma-1"
                      color="black"
                      plain
                      @click="incentive_report()"
                    >
                      Incentive Report
                    </v-btn>
                  </v-col>
                </v-col>
                
              </v-row> -->
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="4"
                  style="padding-top: 0px"
                  v-if="screen_auth('SCN_CHCK_MY_CHECKLIST', 'add_screen')"
                >
                  <v-btn
                    class="ma-1"
                    color="black"
                    plain
                    @click="open_checklist()"
                  >
                    My Checklists
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  style="padding-top: 0px"
                  v-if="screen_auth('SCN_TKT_TICKETS', 'add_screen')"
                >
                  <v-btn
                    class="ma-1"
                    color="black"
                    plain
                    @click="open_ticket()"
                  >
                    My Tickets
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  style="padding-top: 0px"
                  v-if="screen_auth('SCN_SURVEY', 'add_screen')"
                >
                  <v-btn
                    class="ma-1"
                    color="black"
                    plain
                    @click="open_survey()"
                  >
                    My Surveys
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  style="padding-top: 0px"
                  v-if="screen_auth('SCN_WALKINS', 'add_screen')"
                >
                  <v-btn
                    class="ma-1"
                    color="black"
                    plain
                    @click="open_walkins()"
                  >
                    My Walkins
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  style="padding-top: 0px"
                  v-if="screen_auth('SCN_VISITS', 'add_screen')"
                >
                  <v-btn class="ma-1" color="black" plain @click="open_visit()">
                    My Visits
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  style="padding-top: 0px"
                  v-if="screen_auth('SCN_CHCK_REPORTS', 'add_screen')"
                >
                  <v-btn
                    class="ma-1"
                    color="black"
                    plain
                    @click="checklist_report()"
                  >
                    Checklists Report
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  style="padding-top: 0px"
                  v-if="screen_auth('SCN_TKT_REPORTS', 'add_screen')"
                >
                  <v-btn
                    class="ma-1"
                    color="black"
                    plain
                    @click="ticket_report()"
                  >
                    Tickets Report
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  style="padding-top: 0px"
                  v-if="screen_auth('SCN_WLK_RPRT', 'add_screen')"
                >
                  <v-btn
                    class="ma-1"
                    color="black"
                    plain
                    @click="walkins_report()"
                  >
                    Walkins Report
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  style="padding-top: 0px"
                  v-if="screen_auth('SCN_VISIT_REPORTS', 'add_screen')"
                >
                  <v-btn
                    class="ma-1"
                    color="black"
                    plain
                    @click="visit_report()"
                  >
                    Visits Report
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  style="padding-top: 0px"
                  v-if="screen_auth('SCN_SURVEY_REPORTS', 'add_screen')"
                >
                  <v-btn
                    class="ma-1"
                    color="black"
                    plain
                    @click="survey_report()"
                  >
                    Survey Report
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  style="padding-top: 0px"
                  v-if="screen_auth('SCN_INCENTIVE_POLICY', 'add_screen')"
                >
                  <v-btn
                    class="ma-1"
                    color="black"
                    plain
                    @click="incentive_report()"
                  >
                    Incentives Report
                  </v-btn>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialog = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <div v-if="domain != 'admin'" class="float-right">
          <v-icon
            class="mr-3"
            size="25"
            v-if="
              JSON.parse(entityData) ? JSON.parse(entityData).length > 1 : false
            "
            color="black"
            @click="switch_entity"
            title="Switch Company"
            >mdi-swap-horizontal</v-icon
          >

          <!-- &nbsp;&nbsp; -->
          <v-spacer></v-spacer>
        </div>
        <b style="margin-right: 4%">{{ user_name }}</b
        >&nbsp;&nbsp;
        <!-- <v-icon size="25" color="black" @click="logout"
          >mdi-power-settings</v-icon
        > -->
        <template>
          <div>
            <v-menu offset-y style="z-index: 15">
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  icon
                  v-bind="attrs"
                  v-on="on"
                  color="#90A4AE"
                  size="53"
                  style="position: absolute; top: 4px; right: 13px"
                  ><v-icon>mdi-account</v-icon>
                </v-avatar>
              </template>
              <v-card>
                <v-list>
                  <v-list-item @click="UpdateProfile">
                    <v-list-item-title class="xyz x-16">
                      <v-icon size="23">mdi-account-details </v-icon> My
                      Profile</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item @click="logout()">
                    <v-list-item-title class="xyz x-17"
                      ><v-icon size="23">mdi-logout</v-icon>
                      Logout</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </template>
      </v-app-bar>
    </div>
    <v-dialog v-model="switch_dialog" persistent scrollable width="600px">
      <v-card>
        <v-card-title>Switch Company</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-radio-group v-model="entity_id" column>
            <v-radio
              @change="getentityname(entity.name)"
              v-for="(entity, index) in entity_dataset"
              :value="entity.id"
              :label="entity.name"
              :key="index"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              color="blue darken-1"
              v-if="entity_ID"
              text
              @click="entity_ID ? (switch_dialog = false) : ''"
            >
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="login()"> Save </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- mobile_view -->
    <v-app-bar
      app
      clipped-left
      class="elevation-2"
      v-if="logged_user_Email != null && show_hide_mob_btn == 2"
      style="background: rgb(5 12 69) !important; height: 67px; position: fixed"
    >
      <!-- <div class="align-center d-flex" v-if="iflag == 0">
        <img
          src="../assets/tectalik_small_transparent1.png"
          width="35px"
          height="30px"
          class="pa-0"
          v-if="!drawer"
        />
        <img
          src="../assets/tectalik logo.png"
          width="130px"
          height="40px"
          class="pa-0"
          v-if="drawer"
        />
      </div> -->
      <div style="flex-grow: 0.03 !important" v-if="iflag == 0"></div>
      <v-app-bar-nav-icon
        style="color: white; top: 2px; left: -20px"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <!-- <v-toolbar-title>Tectalik</v-toolbar-title> -->
      <b
        style="
          color: white;
          margin-left: -28px;
          margin-top: 9px;
          font-size: 20px;
        "
        class="d-flex justify-content-center"
        >{{ entity_name
        }}<img
          src="../assets/tectalik_small_transparent1.png"
          width="35px"
          height="30px"
          class="pa-0"
          style="
            margin-left: -57px;
            margin-top: -2px;
            opacity: 0.2;
            blend: lighten;
          "
          v-if="!drawer"
      /></b>
      <v-btn
        small
        fab
        width="43px"
        height="40px"
        style="margin-left: 40px; margin-top: 2px"
        color="#FFF59D"
        v-if="badge_flag == true && !drawer"
        @click="refresh_token()"
      >
        <v-icon size="25">mdi-refresh</v-icon>
        <!-- <template v-slot:activator="{ on, attrs }"> -->
        <!-- <v-badge
              bordered
              color="red"
              overlap
              v-if="badge_flag == true"
            >
              <span>
                <v-icon v-bind="attrs" v-on="on"
                  >mdi-refresh</v-icon
                >
              </span>
            </v-badge>
          </template> -->
        <!-- <h4 style="padding-top: 3px">upgrade</h4> -->
      </v-btn>
      <v-spacer></v-spacer>
      <div v-if="domain != 'admin'" class="float-right">
        <v-icon
          class="mr-1"
          style="top: 2px; left: -46px"
          size="25"
          v-if="
            JSON.parse(entityData) ? JSON.parse(entityData).length > 1 : false
          "
          color="white"
          @click="switch_entity"
          title="Switch Company"
          >mdi-swap-horizontal</v-icon
        >
        <!-- &nbsp;&nbsp; -->
        <v-spacer></v-spacer>
        <div v-if="show_hide_mob_btn == 2">
          <v-menu offset-y style="z-index: 15">
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                icon
                v-bind="attrs"
                v-on="on"
                color="#FFF59D"
                size="53"
                style="
                  align-items: baseline;
                  height: 40px;
                  min-width: 43px;
                  width: 43px;
                  position: absolute;
                  top: 9px;
                  right: 6%;
                "
                ><v-icon>mdi-account</v-icon>
              </v-avatar>
            </template>
            <v-list>
              <v-list-item @click="UpdateProfile">
                <v-list-item-title class="xyz x-18">
                  <v-icon size="23">mdi-account-details </v-icon> My
                  Profile</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="logout()">
                <v-list-item-title class="xyz x-19"
                  ><v-icon size="23">mdi-logout</v-icon>
                  Logout</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <!-- <b style="color:white;font-size:10px;">{{ user_name }}</b -->
      >&nbsp;&nbsp;

      <!-- <v-icon size="25" color="white" @click="logout">mdi-power-settings</v-icon> -->
    </v-app-bar>
    <v-dialog
      transition="dialog-bottom-transition"
      v-if="show_hide_mob_btn == 2"
      v-model="switch_dialog"
      fullscreen
      persistent
      width="600px"
    >
      <v-card style="position: fixed">
        <div>
          <div style="background: rgb(5 12 69) !important">
            <img
              src="../assets/company.png"
              width="100%"
              height="250vh"
              style="opacity: 40%; mix-blend-mode: lighten"
              class="pa-0"
            />
            <div>
              <v-icon
                color="white"
                style="position: absolute; top: 22px; right: 23px"
                v-if="entity_ID"
                @click="entity_ID ? (switch_dialog = false) : ''"
                >mdi-close</v-icon
              >
            </div>
          </div>
          <div>
            <v-col
              style="
                overflow: visible;
                margin-top: -41px;
                background: #ffffff;
                border-radius: 40px 40px 0px 0px;
              "
            >
              <h3 style="margin-left: 10px; margin-top: 30px">
                Hey <b>{{ user_name }}</b
                >&nbsp;&nbsp;
              </h3>
            </v-col>
            <v-card-title style="margin-top: -24px; margin-left: 6px"
              >Select Company</v-card-title
            >
            <v-divider></v-divider>
            <v-card-text style="height: 237px; margin-left: 22px">
              <v-radio-group
                v-model="entity_id"
                column
                style="margin-right: 63px"
              >
                <v-radio
                  @change="getentityname(entity.name)"
                  v-for="(entity, index) in entity_dataset"
                  :value="entity.id"
                  :label="entity.name"
                  :key="index"
                ></v-radio>
              </v-radio-group>
            </v-card-text>
            <v-divider></v-divider>
            <v-btn
              rounded
              style="
                width: 295px;
                top: 17px;
                background: rgb(5, 12, 69) !important;
                color: white;
                margin-left: 40px;
                height: 47px;
                text-transform: none;
                font-size: 20px;
              "
              text
              @click="login()"
            >
              Continue
            </v-btn>
          </div>
          <v-card-actions>
            <v-col class="text-right">
              <!-- <v-btn
              color="blue darken-1"
              v-if="entity_ID"
              text
              @click="entity_ID ? (switch_dialog = false) : ''"
            >
              Cancel
            </v-btn> -->
            </v-col>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <v-main id="scrolling-techniques-7" class="custom_bgColor">
      <router-view :key="$route.path"></router-view>
    </v-main>
    <template>
      <v-bottom-navigation
        :value="value"
        color="rgb(5, 12, 68) !important"
        style="
          overflow: hidden;

          position: fixed;
          bottom: 0;
          justify-content: start;
          z-index: 10;
          overflow-x: scroll;
          height: 63px;
        "
        v-if="show_hide_mob_btn == 2"
      >
        <v-btn>
          <span style="color: rgb(0, 1, 79)" @click="backups()">Home</span>
          <img
            src="../assets/tectalik_small_transparent1.png"
            width="30px"
            height="25px"
            class="pa-0"
            style="color: rgb(0, 1, 79)"
            v-if="!drawer"
          />
        </v-btn>

        <v-btn style="margin-left: -6%" v-if="checkFMS" @click="backupstick()">
          <span style="color: rgb(0, 1, 79)">FMS</span>

          <img
            src="../assets/FMSicon2.png"
            width="30px"
            height="25px"
            class="pa-0"
            style="color: rgb(0, 1, 79)"
            v-if="!drawer"
          />
        </v-btn>
        <v-btn @click="backups5()" style="margin-left: -3%">
          <span style="color: rgb(0, 1, 79)">Strategy</span>
          <v-icon style="color: rgb(0, 1, 79)">mdi-alpha-s-circle</v-icon>
        </v-btn>
        <v-btn @click="backups1()" style="margin-left: -3%">
          <span style="color: rgb(0, 1, 79)">Team</span>
          <v-icon style="color: rgb(0, 1, 79)">mdi-alpha-t-circle</v-icon>
        </v-btn>
        <v-btn @click="backups2()" style="margin-left: -5%">
          <span style="color: rgb(0, 1, 79)">Act</span>
          <v-icon style="color: rgb(0, 1, 79)">mdi-alpha-a-circle</v-icon>
        </v-btn>
        <v-btn @click="backups4()" style="margin-left: -5%">
          <span style="color: rgb(0, 1, 79)">Review</span>
          <v-icon style="color: rgb(0, 1, 79)">mdi-alpha-r-circle</v-icon>
        </v-btn>
        <v-btn @click="backups3()" style="margin-left: -5%">
          <span style="color: rgb(0, 1, 79)">Reward</span>
          <v-icon style="color: rgb(0, 1, 79)">mdi-alpha-r-circle</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </template>
  </v-app>
</template>

<script>
import { format, parseISO } from "date-fns";
import menu_list from "./menu_list.js";
import axios from "axios";
import Package from "/package.json";
import { messaging } from "../views/FMS/firebase";
import { getToken } from "firebase/messaging";
import moment from "moment";
// import _ from 'lodash';
export default {
  mounted: function () {
    this.app_version();
    this.items = menu_list;
    this.onLoadDataset(); //method1 will execute at pageload
    // console.log(menu_list, "menu_list");
    this.username = localStorage.getItem("USER_NAME");
    this.entity_name = localStorage.getItem("entity_name");
    this.entity_id = parseInt(localStorage.getItem("ENTITY_ID"));
    this.entity_ID = parseInt(localStorage.getItem("ENTITY_ID"));

    this.entity_id_list = localStorage.getItem("USER_ENTITY_DATASET");
    this.entityData = localStorage.getItem("entitydata");
    console.log(this.entityData, "469");

    if (this.domain != "admin") {
      if (
        !this.entity_ID &&
        (JSON.parse(this.entityData)
          ? JSON.parse(this.entityData).length > 1
          : false)
      ) {
        // console.clear();
        this.switch_entity();
      } else if (this.entityData && JSON.parse(this.entityData).length === 1) {
        let enty = JSON.parse(this.entityData);
        localStorage.setItem("ENTITY_ID", enty[0].id);
        localStorage.setItem("entity_name", enty[0].name);
        this.entity_ID = localStorage.getItem("ENTITY_ID");
        this.update_user_data();
      }
    }
    this.expiry_date = localStorage.getItem("expiry_date");
    console.log(this.expiry_date, "expiry_date home");
    this.current_date = moment().format("YYYY-MM-DD");
    this.diff_date = moment(this.expiry_date).diff(this.current_date, "days");
    console.log(this.diff_date, "diff_date");
    if (this.diff_date < 3) {
      this.badge_flag = true;
    }
  },
  data: () => ({
    applicationVersion: Package.version,
    // resultats: [],
    resultat: localStorage.getItem("resultat"),
    menu: false,
    checkFMS: localStorage.hasOwnProperty("tectalik_product_list")
      ? localStorage.getItem("tectalik_product_list").includes("TECTALIK_FMS")
      : false,
    overlay_load: false,
    url_break: [],
    entity_name: null,
    entity_id: null,
    entity_dataset: null,
    survey_walkin: false,
    domain: localStorage.getItem("domain"),
    switch_dialog: false,
    entity_ID: null,
    entityData: null,
    entity_dataset: [],
    user: true,
    getuser: [],
    device_token: null,
    user_name: localStorage.getItem("USER_NAME"),
    user_id: localStorage.getItem("USER_ID"),
    branch_ID: localStorage.getItem("BRANCH_ID"),
    drawer: false,
    items: [],
    mini: false,
    dialog: false,
    value: false,
    showimage: false,
    show_hide_mob_btn: 0,
    full_or_modal: 0,
    window: {
      width: 0,
      height: 0,
    },
    iflag: 0,
    logged_user_Email: localStorage.getItem("USER_EMAIL"),
    old_token: localStorage.getItem("TOKEN"),
    expiry_date: null,
    current_date: null,
    diff_date: null,
    survey_flag: false,
    badge_flag: false,
  }),

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  created() {
    window.addEventListener("resize", this.handleMobile);
    this.handleMobile();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleMobile);
  },
  methods: {
    refresh_token() {
      window.axios = require("axios");
      axios
        .post("/update_refresh_token/" + this.old_token)
        .then((response) => {
          localStorage.removeItem("TOKEN");
          localStorage.setItem("TOKEN", response.data.new_token);
          let tkn = localStorage.getItem("TOKEN");
          console.log(tkn, "tkn");
          this.update_user_data();
        })
        .catch((err) => {});
    },
    update_user_data() {
      let prefix = "cmp_" + this.domain;
      // let prefix = "cmp_" + this.prefix;
      prefix = prefix == "cmp_admin" ? "public" : prefix;
      const user_dataset = {
        user_dataset: {
          logged_date: moment(),
          expiry_date: moment().add(7, "days"),
        },
      };
      this.$api
        .put(
          "/update_user_logged_data/" + this.user_id + "/" + prefix,
          user_dataset
        )
        .then((response) => {
          let expiry_date = moment().add(7, "days").format("YYYY-MM-DD");
          console.log(expiry_date, "expiry_date");
          localStorage.removeItem("expiry_date");
          localStorage.setItem("expiry_date", expiry_date);
          const name = localStorage.getItem("expiry_date");
          console.log(name, "name");
          this.badge_flag = false;
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    requestPermission() {
      console.log("Requesting permission...");
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted. home");
          getToken(messaging, {
            vapidKey:
              "BMy2wqMcf-2AcJJ7sk-gzMrmBRMmztdQ5UqSGDnfJcQceQ7-kLk8t2StTk3zh1qXCk3Dqd-tvDtjpvty7hZkR5Q",
          })
            .then((currentToken) => {
              if (currentToken) {
                this.device_token = currentToken;
                console.log(this.device_token, "current token");
                this.store_token();
              } else {
                this.device_token = null;
                console.log(
                  "No registration token available. Request permission to generate one."
                );
                this.clearStorage();
              }
            })
            .catch((err) => {
              this.clearStorage();
              console.log("An error occurred while retrieving token. ", err);
              this.clearStorage();
            });
        } else {
          console.log("No Permission!");
          this.clearStorage();
        }
      });
    },
    store_token() {
      // this.device_token =
      //   "dq0UmJLl8WWX1sIgLvLCZo:APA91bGWBnvPbOudCeiWN5lRXVkp2I6DTy5p3PtqpB8gJV7Ub1EK7EvE_PjeHqHrkHl0mVlIrxNg5FRHotRkmsK04TcAeOF3pmQdyJRCYhE8fp8Xbt63c8bsriTaURqwav5HQOjPxZvp";
      console.log(this.device_token);
      const user_id = parseInt(this.user_id);
      console.log(user_id);
      const params = {
        params: {
          device_token: this.device_token,
        },
      };
      this.$api
        .put("update_users_device_logout/" + user_id, params)
        .catch((err) => console.log(err))
        .finally(() => {
          this.clearStorage();
        });
    },
    clearStorage() {
      localStorage.removeItem("ENTITY_ID");
      localStorage.removeItem("TOKEN");
      localStorage.clear();
      this.$router.push({ path: "/login" });
    },
    open_ticket() {
      this.dialog = false;
      this.$router.push("ticket");
    },
    open_checklist() {
      this.dialog = false;
      this.$router.push("MainChecklist");
    },
    open_survey() {
      this.dialog = false;
      this.$router.push("survey");
    },
    open_visit() {
      this.dialog = false;
      this.$router.push("/visits/landing");
    },
    open_walkins() {
      this.dialog = false;
      this.$router.push("/walkin/landing");
    },
    incentive_report() {
      this.dialog = false;
      this.$router.push("incentive_reports");
    },
    actual_report() {
      this.dialog = false;
      this.$router.push("actual_reports");
    },
    survey_report() {
      this.dialog = false;
      this.$router.push("survey_reports");
    },
    visit_report() {
      this.dialog = false;
      this.$router.push("visitReport");
    },
    walkins_report() {
      this.dialog = false;
      this.$router.push("/walkins/reports");
    },
    ticket_report() {
      this.dialog = false;
      this.$router.push("tickets_report");
    },
    checklist_report() {
      this.dialog = false;
      this.$router.push("showlist_all_report");
    },
    addsurvey() {
      this.dialog = false;
      this.$router.push("/survey/add");
    },
    addgoals() {
      this.dialog = false;
      this.$router.push({ path: "business_plan", query: { flag: "add" } });
    },
    addwalkins() {
      this.dialog = false;
      this.$router.push({ path: "/walkin/landing", query: { flag: "add" } });
    },
    planvisit() {
      this.dialog = false;
      this.$router.push({
        path: "/visits/landing",
        query: { flag: "planadd" },
      });
    },
    logvisit() {
      this.dialog = false;
      this.$router.push({ path: "/visits/landing", query: { flag: "add" } });
    },
    mainchecklist() {
      this.dialog = false;
      this.$router.push({ path: "MainChecklist", query: { flag: "added" } });
    },
    addkpi(flag) {
      this.dialog = false;
      this.$router.push("kpi_addition_tenant");
    },
    close_ticket() {
      this.dialog = false;
      this.$router.push("/ticket/add");
    },
    addService() {
      this.dialog = false;
      this.$router.push("/service/add");
    },
    addServiceCategory() {
      this.dialog = false;
      this.$router.push("/servicecategory/add");
    },
    add_employee() {
      this.dialog = false;
      this.$router.push("/employee_mstr/add");
    },
    getentityname(name) {
      localStorage.setItem("entity_name", name);
      // console.log(name,"name")
    },
    checkproductvalue(item) {
      // return true;
      // console.log(item.product_code ,'item.product_code ')
      var product_list = localStorage.getItem("tectalik_product_list");
      var domain = localStorage.getItem("domain");
      var features_list = localStorage.getItem("features_list");

      let module_rights_dataset = JSON.parse(
        localStorage.getItem("MODULE_RIGHTS_DATASET")
      );
      let screen_right_dataset = JSON.parse(
        localStorage.getItem("SCREEN_RIGHT_DATASET")
      );

      let common_screen_right_dataset = JSON.parse(
        localStorage.getItem("COMMON_SCREEN_RIGHT_DATASET")
      );

      let flags_module = 0;
      let flags_screen = 0;
      var flags = 0;
      var features_flags = 0;
      var product_code = item.product_code;
      var screen_code = item.screen_code;

      let module_codeflag = [];
      let screen_codeflag = [];
      if (item.module_codeflag) {
        module_codeflag = item.module_codeflag;
      }
      if (item.screen_codeflag) {
        screen_codeflag = item.screen_codeflag;
      }

      // console.log(features_list,'features_list',item.id)
      let orolist = ["Customer_Target", "Customer_Report", "Dashboard"];
      if (domain != "admin") {
        // console.log(item.name == 'Dashboard' && domain != oro);
        if (
          orolist.includes(item.name) &&
          domain != "oro" &&
          domain != "align"
        ) {
          return false;
        }
        // let oronew = ["oro", "align"];
        // if (orolist.includes(item.name) && !oronew.includes(domain)) {
        //   return false;
        // }

        if (item.isnonadmintoshow) {
          if (process.env.NODE_ENV === "production") {
            if (item.isproductiontohide) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        } else {
          if (!item.iscommontoshow) {
            if (process.env.NODE_ENV === "production") {
              if (item.isproductiontohide) {
                return false;
              }
            }
            if (
              product_list != null &&
              product_list != "" &&
              product_list != undefined &&
              product_list.length != 0
            ) {
              product_list = product_list.split(",");
              if (
                product_code != null &&
                product_code != "" &&
                product_code != undefined &&
                product_code.length != 0
              ) {
                let idex = product_list.filter(function (item) {
                  return product_code.indexOf(item) !== -1;
                });
                if (idex.length !== 0) {
                  flags++;
                }
              }
            }
            if (
              module_rights_dataset != null &&
              module_rights_dataset != "" &&
              module_rights_dataset != undefined &&
              module_rights_dataset.length != 0
            ) {
              var filteredmoudule = module_rights_dataset.filter(function (
                item
              ) {
                return module_codeflag.indexOf(item.module_code) !== -1;
              });
              for (var i = 0; i < filteredmoudule.length; i++) {
                if (filteredmoudule[i].active) {
                  flags_module++;
                  break;
                }
              }
            }
            if (
              screen_right_dataset != null &&
              screen_right_dataset != "" &&
              screen_right_dataset != undefined &&
              screen_right_dataset.length != 0
            ) {
              var filteredScreen = screen_right_dataset.filter(function (item) {
                return screen_codeflag.indexOf(item.screen_code) !== -1;
              });
              for (var i = 0; i < filteredScreen.length; i++) {
                if (filteredScreen[i].landing) {
                  flags_screen++;
                  break;
                }
              }
            }
            //
            if (flags > 0 && flags_module > 0 && flags_screen > 0) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
      } else {
        if (!item.iscommontoshow) {
          let flags_screen = 0;
          if (
            common_screen_right_dataset != null &&
            common_screen_right_dataset != "" &&
            common_screen_right_dataset != undefined &&
            common_screen_right_dataset.length != 0
          ) {
            var filteredScreen = common_screen_right_dataset.filter(function (
              item
            ) {
              return screen_codeflag.indexOf(item.screen_code) !== -1;
            });
            for (var i = 0; i < filteredScreen.length; i++) {
              if (filteredScreen[i].landing) {
                flags_screen++;
                break;
              }
            }
          }

          if (flags_screen > 0) {
            return true;
          } else {
            return false;
          }
          // return true;
        } else {
          return true;
        }
      }

      //       if (navigator.userAgent.match(/(iPhone)/)){
      //   if((screen.availHeight == 812) && (screen.availWidth == 375)){

      //     if((window.innerHeight == "375") && (window.innerWidth == "812")){
      //       // iPhone X Landscape

      //     }else{
      //       // iPhone X Portrait
      //     }
      //   }
      // }
      if (this.show_hide_mob_btn == 2) {
        this.iflag == 1;
      } else {
        this.iflag == 0;
      }
    },
    handleMobile() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width <= 576) {
        this.show_hide_mob_btn = 2;
        this.full_or_modal = 1;
      } else {
        this.show_hide_mob_btn = 1;
        this.full_or_modal = 0;
      }
    },
    backupstick() {
      this.$router.push("/fms_home");
    },
    backups() {
      var product_get = localStorage.getItem("tectalik_product_list");
      let product_list = [];
      if (
        product_get != null &&
        product_get != "" &&
        product_get != undefined &&
        product_get.length != 0
      ) {
        product_list = product_get.split(",");
      }

      if (product_list.includes("TECTALIK_FMS")) {
        this.$router.push("/welcome_page");
      } else {
        this.$router.push("/tasks_detail_mobile");
      }
    },
    backups1() {
      this.$router.push("/team_list");
    },
    backups2() {
      this.$router.push("/quick_links");
    },
    backups3() {
      this.$router.push("/score_list");
    },
    backups4() {
      this.$router.push("/kpi_bots");
    },
    backups5() {
      this.$router.push("/set_strategy");
    },

    UpdateProfile() {
      this.$router.push({ path: "/My_profile" });
    },
    router_pusher(path = "") {
      if (this.$route.path != path) {
        this.$router.push({ path: path });
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width <= 576) {
        this.iflag = 1;
      } else {
        this.iflag = 0;
      }
      // console.log(this.iflag,'iflag')
    },

    profile() {
      this.$router.push("#ProfilePMS");
    },
    openmenu() {
      this.drawer = !this.drawer;
      this.showimage = !this.showimage;
      // console.log(this.drawer,'this.drawer');
      // console.log(this.showimage,'this.showimage');
    },
    login() {
      if (isNaN(this.entity_id) == true || this.entity_id == null) {
        return;
      }
      localStorage.setItem("ENTITY_ID", this.entity_id);
      var url_string = "";
      var url_break = window.location.pathname.split("/");
      url_break = url_break[1];
      // let prefix= "cmp_" + this.domain;
      // prefix = prefix == 'cmp_admin' ? 'public' : prefix ;
      // const user_dataset = {
      //     // user_dataset: {
      //      logged_date: moment().format("YYYY-MM-DD HH-MM-SS"),
      //      expiry_date: moment().add(7, "days").format("YYYY-MM-DD HH-MM-SS"),
      //     // }
      // }
      if (
        url_break == "checklist_Template_page" ||
        url_break == "kpi_additions" ||
        url_break == "templatelibrary" ||
        url_break == "appraisal_add"
      ) {
        url_string = "tapi/";
      }
      axios
        .get(url_string + "get_email_by_entity", {
          params: {
            email: localStorage.getItem("USER_EMAIL"),
            entity_id: this.entity_id,
            // user_dataset: user_dataset,
            // logged_date: logged_date,
            // expiry_date: expiry_date,
            // prefix: prefix,
            user_id: this.user_id,
          },
        })
        .then((response) => {
          console.log(response.data, "get_email_by_entity");
          // let expiry_date = moment().add(7, "days").format("YYYY-MM-DD");
          //   console.log(expiry_date,"expiry_date");
          //   localStorage.setItem("expiry_date", expiry_date);
          //   const name = localStorage.getItem("expiry_date");
          //   console.log(name,"name");
          let employee_dataset = response.data.emp_details;

          let rating_setting = response.data.rating_setting;
          if (rating_setting.length) {
            let self_rating_setting = rating_setting[0].self_rating
              ? "yes"
              : "no";
            localStorage.setItem("Self_Rating_Setting", self_rating_setting);
          } else {
            localStorage.setItem("Self_Rating_Setting", "yes");
          }
          let calc_settings = response.data.calc_settings;
          if (calc_settings.length) {
            let calc = calc_settings[0].show == true ? "yes" : "no";
            localStorage.setItem("kpi_calc", calc);
          } else {
            localStorage.setItem("kpi_calc", "yes");
          }

          if (employee_dataset.length) {
            localStorage.setItem("LOGGED_EMP_ID", employee_dataset[0].id);
            localStorage.setItem("LOGGED_EMP_NO", employee_dataset[0].empno);
          } else {
            localStorage.setItem("LOGGED_EMP_ID", 0);
            localStorage.setItem("LOGGED_EMP_NO", 0);
          }
          let pl = [];
          let product_list = [];
          let product_list_item = response.data.products_by_users;
          if (product_list_item.length != 0) {
            pl = product_list_item.map((a) => a.product_code);
          } else {
            pl = response.data.data.product_list;
          }

          if (pl == "" || pl == null) {
            product_list = [];
          } else {
            product_list = pl;
          }
          // console.log(response, 'response')
          localStorage.setItem("tectalik_product_list", product_list);

          this.set_local_storage(
            response.data.modules_by_users,
            response.data.screen_by_users
          );
          localStorage.setItem("USER_ID", response.data.data.id);
          localStorage.setItem("USER_NAME", response.data.data.name);
          localStorage.setItem("BRANCH_ID", response.data.data.branch_id);
          // console.log(localStorage.getItem("USER_ID"),"userid");
          this.switch_dialog = true;
          location.reload();
        });
      this.overlay_load = true;
      // this.switch_dialog = true;
      // location.reload();
    },
    set_local_storage(module_dataset = [], screen_dataset = []) {
      localStorage.setItem(
        "MODULE_RIGHTS_DATASET",
        JSON.stringify(module_dataset)
      );
      for (let i = 0; i < screen_dataset.length; i++) {
        screen_dataset[i]["hide_all"] = false;
        screen_dataset[i]["module_name"] = false;
        for (let j = 0; j < module_dataset.length; j++) {
          if (screen_dataset[i].modules_rights_id == module_dataset[j].id) {
            screen_dataset[i]["hide_all"] = module_dataset[j].active;
            screen_dataset[i]["module_name"] = module_dataset[j].module_name;
          }
        }
      }
      localStorage.setItem(
        "SCREEN_RIGHT_DATASET",
        JSON.stringify(screen_dataset)
      );
    },

    logout() {
      const token_update = {
        token: null,
        email: this.logged_user_Email,
        product_code: "PRMS",
        sub_domain: this.domain,
      };
      axios
        .post("analytics_token_update", token_update)
        .then((res) => {
          // console.log('logout')
        })
        .catch((err) => {})
        .finally(() => {
          this.clearStorage();
        });
    },
    switch_entity() {
      this.switch_dialog = true;

      let data = JSON.parse(localStorage.getItem("entitydata"));
      this.entity_dataset = [];
      for (let i = 0; i < data.length; i++) {
        {
          this.entity_dataset.push({
            id: data[i].id,
            name: data[i].name,
          });
        }
      }
    },
    // logout() {
    //   localStorage.removeItem("TOKEN");
    //   this.$router.push({ path: "/login" });
    // },
    onLoadDataset() {
      window.axios = require("axios");
      // let item = this.items;

      // let dummy_arr = [];

      // for (var i = 0; i < item.length; i++) {
      //   if (item[i].children) {
      //     let child = item[i].children;
      //     let child_arr = [];
      //     for (var k = 0; k < child.length; k++) {
      //       let ccc = this.screenrights_fn(
      //         child[k]["screen_code"],
      //         2,
      //         child[k]["to"],
      //         child[k],
      //       );
      //       if (ccc == 1) {
      //         child_arr.push(child[k]);
      //       }
      //     }
      //     // console.log(child_arr,'child_arr');
      //     if (child_arr.length != 0) {
      //       dummy_arr.push({
      //          id: item[i].id,
      //           name: item[i].name,
      //           screen_code: item[i].screen_code,
      //           children: child_arr,
      //           has_children : item[i].has_children,
      //           product_code : item[i].product_code,
      //           icon : item[i].icon ,
      //       });
      //     }
      //   } else {

      //     if (process.env.NODE_ENV === 'production') {
      //       if (item[i]["to"] != '/timeline' && item[i]["to"] != '/timelinebar' && item[i]["to"] != '/indiamap') {
      //           let cci = this.screenrights_fn(
      //           item[i]["screen_code"],
      //           2,
      //           item[i]["to"],
      //           item[i]
      //           );
      //           // console.log(cci,item[i]['screen_code'],2,item[i]['to'])
      //           if (cci == 1) {
      //           dummy_arr.push(item[i]);
      //           }
      //       }
      //     }else{
      //       let cci = this.screenrights_fn(
      //       item[i]["screen_code"],
      //       2,
      //       item[i]["to"],
      //       item[i]
      //       );
      //       // console.log(cci,item[i]['screen_code'],2,item[i]['to'])
      //       if (cci == 1) {
      //       dummy_arr.push(item[i]);
      //       }
      //     }

      //   }
      // }

      // // console.log(dummy_arr,'dummy_arr');

      // this.items = [];
      // this.items = dummy_arr;
      // console.clear();
    },
    //   hasNotch() {
    //   if (CSS.supports('padding-bottom: env(safe-area-inset-bottom)')) {
    //     let div = document.createElement('div');
    //     div.style.paddingBottom = 'env(safe-area-inset-bottom)';
    //     document.body.appendChild(div);
    //     let calculatedPadding = parseInt(window.getComputedStyle(div).paddingBottom, 10);
    //     document.body.removeChild(div);
    //     if (calculatedPadding > 0) {
    //       return true;
    //     }
    //   }
    //   return false;
    // },

    screenrights_fn(code, flag, to, item) {
      // console.log(code,'code',flag,'flag',to,'to');

      var features_list = localStorage.getItem("features_list");
      var domain = localStorage.getItem("domain");
      var flags = 0;

      if (domain != "admin") {
        if (
          features_list != null &&
          features_list != "" &&
          features_list != undefined &&
          features_list.length != 0
        ) {
          features_list = features_list.split(",");
          for (var i = 0; i < features_list.length; i++) {
            if (code == features_list[i]) {
              flags++;
              break;
            }
          }
        }

        if (to == "/login" || to == "/show_list") {
          // return '';
          return 1;
        } else {
          if (flags > 0) {
            // return '';
            return 1;
          } else {
            return 0;
            // return 'd-none';
          }
        }
      } else {
        // return '';
        // to == "/login" || to == "/client" || to == "/Industry" || to == "/product" || to == "/checklist_Template_page" || to == "/process" || to == "/kpiroles" || to == "/tkt_service_category"
        if (item.iscommontoshow) {
          return 1;
        }
        return 0;
      }
    },
    app_version() {
      this.currentUrl = window.location.href;
      const urlParts = this.currentUrl.split("/");
      let rout = urlParts[3];

      if (this.applicationVersion != this.resultat) {
        console.log(this.resultat, this.applicationVersion);
        if (
          rout == "health_check_answer" ||
          rout == "finance_health_questions" ||
          rout == "health_check_dashboard" ||
          rout == "health_check_dashboard_view"
          
        ) {
        } else {
          alert("New version is out! Please logout and re-login to continue");
          localStorage.setItem("resultat", this.applicationVersion);
        }
      }

      // else if(this.resultat == this.applicationVersion){
      // console.log(this.resultat,this.applicationVersion)
      //   alert("Not Availabel");
      // }
    },
  },
};
</script>